<script setup lang="ts">
import { useTheme } from 'vuetify'
import axios from 'axios'
import { useThemeConfig } from '@core/composable/useThemeConfig'
import { hexToRgb } from '@layouts/utils'

import { userDetailStore } from '@/stores/userdata'
import { useHelpdeskStore } from "@/stores/helpdesk"
import { useVerticalMenuStore } from '@/stores/verticalmenu'
import { useLoaderStore } from '@/stores/loader';

import { useSnackbarStore } from '@/stores/snackbarStore'

import HelpDesk from '@/views/helpdesk.vue';
// import addMemberDrawer from '@/views/addMemberDrawer.vue';
// import addClientDrawer from '@/views/addClientDrawer.vue';
import addMilestoneDrawer from '@/views/addMilestoneDrawer.vue'
import WelcomeTour from '@/views/welcomeTour.vue';
import supportSvg from "@/assets/images/newtheme/ws_chat_icon.svg";
import cancelSvg from "@/assets/images/newtheme/cross_chat.svg";
import { useRoute, useRouter } from 'vue-router';
import { useMilestoneSettingStore } from "@/stores/milestonesetting.ts"  
import { sidemenuService } from "@/services/sidemenu"
import { useRolelistStore } from "@/stores/rolelist";
import { useDrawer } from "@/composable/drawerComposable";
import { logoutService } from './services/authentication/logoutService'
const { unionArray, selectDrawers } = useDrawer();

const cancelSource = axios.CancelToken.source();
const milestoneSetting = useMilestoneSettingStore();
const roleListData = useRolelistStore()
// declare var $crisp;
const { syncInitialLoaderTheme, syncVuetifyThemeWithTheme: syncConfigThemeWithVuetifyTheme, isAppRtl } = useThemeConfig()

const { global } = useTheme()
const { locale } = useI18n({ useScope: 'global' })
const route = useRoute();
const router = useRouter();
const sidemenuServices = sidemenuService()
if (localStorage.getItem('currentLang') !== null) {
  locale.value = localStorage.getItem('currentLang')
  // isAppRtl.value = localStorage.getItem('currentLang') === 'ar'
}
const userData = userDetailStore()
const veritcalMenu = useVerticalMenuStore()
const helpdeskStatus = useHelpdeskStore()
import { documentationObj } from '@types/sidebar'
const loader = useLoaderStore();

// ℹ️ Sync current theme with initial loader theme
syncInitialLoaderTheme()
syncConfigThemeWithVuetifyTheme()

const userRolePermission = async () => {
  if (localStorage.hasOwnProperty("userData") && Object.keys(JSON.parse(localStorage.getItem('userData'))).length > 0) {
    let userDetails = JSON.parse(localStorage.getItem('userData'));

      // if user role is not client(6) then show menu from menu api
    if(userData.userRole.role_id != 6){
      fetchSideMenu()
    }

    await fetchUserRolesPermission(userDetails.org_id);
    if (userData.orgDetails.length === 0 && Object.keys(userDetails).length) {
      await getOrgData(userDetails.org_id);
    }
    checkSettingPermission(userDetails.org_id);
    fetchSelectedLang();
  }
}

const fetchUserRolesPermission = async (orgId: Number) => {
  loader.showLoader(true)
  axios.post('/v1/user/organization/role/list', {
    organization_id: orgId
  }).then((res) => {
    if (res.data.response.code == 200) {
      roleListData.timesheetpermission = res.data.response.data.modify_value;
      let userRole = {
        id: res.data.response.data.role.id,
        name: res.data.response.data.role.name,
        slug: res.data.response.data.role.slug,
        role_id: res.data.response.data.role_id,
        status: res.data.response.data.status
      }
      let sidemenu = res.data.response.data.role.menu;
      for (const menu of sidemenu) {
          // Check if menu_id is "dashboard"
          if (menu.menu_id == "dashboard") {
            // Iterate through children of "dashboard" menu
            for (const child of menu.children) {
              // Check if child also has menu_id "dashboard"
              if (child.menu_id == "dashboard") {
                // Update its url key
                if(userRole.role_id == 5 || userRole.role_id == 2 || userRole.role_id == 3 || res.data.response.data.is_team_lead == 1 || res.data.response.data.is_department_head == 1 || res.data.response.data?.is_project_manager == 1){
                  child.url = "/dashboard/all";
                }
              }
            }
          }
        }
      localStorage.setItem('userRoles', JSON.stringify(res.data.response.data.role.permission));

      // if user role is client(6) then show menu from role/list api
      if(userRole.role_id == 6){
        let featuresList = res.data.response?.settings ? res.data.response?.settings : []
        localStorage.setItem("featuresList", JSON.stringify(featuresList))
        veritcalMenu.featuresList = featuresList;

        if(featuresList.some(feature => feature?.name == "project_management" && feature.status == 1)){
          sidemenu.forEach(menu => {
          if (menu.title == "Task Management") {
            menu.title = "Projects";
          }
        });
        }else{
          sidemenu.forEach(menu => {
            if(menu.title == "Task Management" || menu.title == "Project Management"){
              menu.title = "Tasks";
            }
        });
        }
        localStorage.setItem('verticalMenu', JSON.stringify(sidemenu));
        veritcalMenu.storeMenu(sidemenu);
      }

      localStorage.setItem('userRole', JSON.stringify(userRole));
      localStorage.setItem('setupSteps', JSON.stringify(res.data.response.steps));
      localStorage.setItem('currentOrgPlanName', res.data.response.data.plan_name)
      userData.setUserPermissions(res.data.response.data.role.permission);
      userData.setUserOrgRole(userRole);
      userData.setUserSetupSteps(res.data.response.steps);

      if (res.data.response.data?.payment) {
          if (res.data.response.data.payment?.plan_id) {
            if(userRole.role_id == 2 || userRole.role_id == "5"){
              // If role is owner or manager, redirect member to payment section, when custom plan is assigned from superadmin
              router.push({ path: "/dashboard/payment-section" });
            }else{
              // Else, Do logout
              logoutService()
            }
          }
      }
    }
  })
}

const getOrgData = async (orgId: Number) => {
  // console.log("org edit -app.vue")
  axios.get(`/v1/organization/edit/${orgId}`, {
  }).then(res => {
    if (res.data.response.code == 200) {
      localStorage.setItem('orgData', JSON.stringify(res.data.response.data));
      userData.setOrganization(res.data.response.data);
    }
  })
}

const fetchSideMenu = async () => {
  // userData.userRole.role_id
  const res = await sidemenuServices.fetchSideMenu()
  let featuresList = res.data.response.data?.settings ? res.data.response.data?.settings : []
  localStorage.setItem("featuresList", JSON.stringify(featuresList))
  veritcalMenu.featuresList = featuresList;
  selectDrawers(featuresList);
  
  let sidemenu = res.data.response.data.menu;
  if(featuresList.some(feature => feature?.name == "project_management" && feature.status == 1)){
    sidemenu.forEach(menu => {
    if (menu.title == "Task Management") {
      menu.title = "Projects";
    }
  });
  }else{
    sidemenu.forEach(menu => {
      if(menu.title == "Task Management" || menu.title == "Project Management"){
        menu.title = "Tasks";
      }
  });
  }
  for (const menu of sidemenu) {
          // Check if menu_id is "dashboard"
          if (menu.menu_id == "dashboard") {
            // Iterate through children of "dashboard" menu
            for (const child of menu.children) {
              // Check if child also has menu_id "dashboard"
              if (child.menu_id == "dashboard") {
                // Update its url key
                if(userData.userRole.role_id == 5 || userData.userRole.role_id == 2 || userData.userRole.role_id == 3 || res.data.response.data.otherkeys.is_team_lead == 1 || res.data.response.data.otherkeys.is_department_head == 1 || res.data.response.data?.otherkeys.is_pm == 1){
                  child.url = "/dashboard/all";
                }
              }
            }
          }
        }
  localStorage.setItem("verticalMenu", JSON.stringify(sidemenu));
  veritcalMenu.storeMenu(sidemenu);

}
const fetchSelectedLang = () => {
  axios.get("/v1/language/get").then((res) => {
    if (res.data.response.code == 200) {
      locale.value = res.data.response.data.i18nLang;
      localStorage.setItem(
        "currentLang",
        (res.data.response.data.i18nLang)
      );
      // isAppRtl.value = res.data.response.data.i18nLang === "ar";
    } else if (res.data.response.code == 401) {
      locale.value = "en";
      localStorage.setItem("currentLang", "en");
    } else {
      locale.value = "en";
      localStorage.setItem("currentLang", "en");
    }
  })
  .catch((err) => {
    locale.value = "en";
    localStorage.setItem("currentLang", "en");
  });
};

import Crisp from '@layouts/crisp';

const myValue = ref(true);
const openSupportDrawer = () => {
  if (Crisp.chat.isChatOpened()) {
    Crisp.chat.close();
    helpdeskStatus.setOpenChatToggle(false);
  }
  else {
    helpdeskStatus.setHelpdeskNavStatus(true);
  }
}
const checkSettingPermission = (orgId: any) => {
  axios.post('/v1/setting/checksettingpermission', {
    org_id: orgId
  }).then((res) => {
    if (res.data.response.code == 200) {
      localStorage.setItem('checkSettingPermission', JSON.stringify(res.data.response.data));
    }
    else {
    }
  }).catch(res => {
  })
}

//showing loader to hide theme's default blank background on refresh
const checkClassWithDelay = async () => {
  await new Promise<void>((resolve) => {
    let timer = setInterval(() => {
      //will show loader untill /layout-wrapper class loads in the DOM
      const element = document.querySelector('.layout-wrapper');
      if (element) {
        myValue.value = false;
        clearInterval(timer);
      }
      resolve();
    }, 100);
  });
};
onMounted(() => {
  // fetchGlobalStatus()
  userRolePermission();
  fetchIPDetails();
  checkClassWithDelay();
  
})
const fetchIPDetails = () => {
  axios.get('https://api.workstatus.io/api/v1/ip', {
    headers: {
      'Accept': '*/*', // Specify the content type you expect
    }
  }).then((response) => {
      userData.setIPAddress(response?.data?.ip)
    getIpDetails()
  }).catch(err => {
    getIpDetails();
  })
}
const getIpDetails = () => {
  axios.get('https://ipinfo.io/45.249.85.36?token=c696f07d1ecb37', {
    headers: {
      'Accept': '*/*', // Specify the content type you expect
    }
  }).then((res: any) => {
    let location = res.data.country == "IN" ? "India" : "Other";
    localStorage.setItem("Location", location);
    localStorage.setItem("Code", res.data.country);
  })
}

// Snackbar code
const snackbarStore = useSnackbarStore();

</script>

<template>
  <VLocaleProvider :rtl="isAppRtl">
    <!-- ℹ️ This is required to set the background color of active nav link based on currently active global theme's primary -->
    <VApp :style="`--v-global-theme-primary: ${hexToRgb(global.current.value.colors.primary)}`">
      <common-snackbar v-model="snackbarStore.snackbar" :showSnackbar="snackbarStore.snackbar" :message="snackbarStore.snackbar_message" :statusCode="snackbarStore.snackbar_status"/> 
      <div class="loader" v-if="myValue">
        <div class="m-auto loader-down-div">
          <div style="loader_internal_div">
            <div style="loader_image_div">
              <img width="60" style="height: 60px;" src="@/assets/images/svg/nobg-loadersmall.gif" alt="logo" />
            </div>
          </div>
        </div>
      </div>
      <RouterView />
      <HelpDesk />
      <!-- <addMemberDrawer /> -->
      <!-- <addClientDrawer /> -->
      <addMilestoneDrawer />
      <WelcomeTour />
      <div  :id="isAppRtl ? 'ws_chat_icon-Rtl' : 'ws_chat_icon'" @click="openSupportDrawer()" v-if="!loader.globalLoader && helpdeskStatus.showChatIcon">
        <img :src="helpdeskStatus.openChat ? cancelSvg : supportSvg" />
      </div>
    </VApp>
  </VLocaleProvider>
</template>
<style scoped>
.loader {
  position: fixed;
  top: 0;
  left: 0;
  background: rgba(255, 255, 255, 1) !important;
  width: 100%;
  height: 100%;
  z-index: 100;
  display: flex;
  align-items: center;
  justify-content: center;
}
.loader_internal_div {
  text-align: center;
  justify-content: center;
  align-items: center;
}
.loader_image_div {
  display:flex;flex-direction:column;align-items:center;
}
</style>