{
  "leave_history_line_1":"Currently, there is no leave history available for the selected user. ",
  "leave_history_line_2":"Please ensure leave requests are submitted and recorded for accurate tracking.",
  "no_attendance_line_1":"Currently, there are no attendance records available.",
  "no_attendance_line_2":"Please ensure attendance is logged to maintain accurate tracking of work hours and presence.",
  "no_attendance_line_3":"Submit a timesheet to update attendance records.",
  "no_timesheet_approval_line_1":"Currently, there are no timesheet approval requests.",
  "no_timesheet_approval_line_2":"Please submit a timesheet for approval to ensure accurate tracking and verification of work hours.",
  "no_timesheet_line_1":"Currently, there are no time logs available.",
  "no_timesheet_line_2":"Please add a new time entry to accurately track your work hours and progress.",
  "no_milestone_yet_line_1":"Looks like you don't have any milestones yet. ",
  "no_milestone_yet_line_2":"Start by creating your first milestone to keep track of your project's progress!",
  "Dashboard": "Tableau de bord",
  "Departments": "Départements",
  "Subscription": "Abonnement",
  "Activity": "Activité",
  "Screenshots": "Captures d'écran",
  "ExplainThisScreen" : "Expliquer cette interface",
  "workstatus-intro" : "Workstatus Intro",
  "VisitHelpCenter" : "Visiter le Centre d'aide",
  "Apps": "Apps",
  "URL": "URLs",
  "Timesheets": "Feuilles de temps",
  "View & Edit Timesheets": "Afficher et modifier les feuilles de temps",
  "Approvals": "Approbations",
  "Attendance": "Présence",
  "Time & Attendance": "Temps et présence",
  "People": "Personnes",
  "Employees": "Employés",
  "Peoples": "Peuples",
  "Members": "Membres",
  "Teams": "Équipes",
  "Project Management": "Gestion de projet",
  "Projects": "Projet",
  "Todo": "Faire",
   "Break": "Pause",
  "Clients": "Clientes",
  "Workorder": "Demande de service",
  "Location": "Emplacement",
  "Job sites": "Chantiers d'emploi",
  "Shift & Leaves": "Shift & Feuilles",
  "Schedules": "Des horaires",
  "Time Off": "Temps libre",
  "Reports": "Rapports",
  "Time & Activity": "Temps et activité",
  "Weekly": "Hebdomadaire",
  "Dynamic Report": "Rapport dynamique",
  "Jobsites" : "Sites de travail",
  "Apps & Urls": "Apps & Urls",
  "Project and Task": "Projet et tâche",
  "Attendance Report - Basic": "Rapport de présence - Basique",
  "Attendance Report - Advanced": "Rapport de présence - Avancé",
  "Jobsite": "Chantier",
  "Time off transaction": "Transaction de congés",
  "All Reports": "Tous les rapports",
  "Settings": "Paramètres",
  "General": "Général",
  "Features": "Caractéristiques",
  "Memberships": "Adhésions",
  "Organisation Management": "Gestion de l'organisation",
  "Organizations": "Gestion de l'organisation",
  "AllSettings": "Tous les paramètres",
  "Change log": "Journal des modifications",
  "Helpdesk": "Bureau d'aide",
  "Integration": "L'intégration",
  "UI Elements": "ÉLÉMENTS DE L'UI",
  "Forms & Tables": "Formulaires et tableaux",
  "Pages": "Des pages",
  "Charts & Maps": "Graphiques et cartes",
  "Others": "Autres",
  "Typography": "Typographie",
  "Cards": "Cartes",
  "Basic": "De base",
  "Advance": "Avance",
  "Widgets": "Widget",
  "Card Action": "Action de la carte",
  "Components": "Composants",
  "Alert": "Alerte",
  "Avatar": "Avatar",
  "Badge": "Badge",
  "Button": "Bouton",
  "Calendar": "Calendrier",
  "Image": "Image",
  "Pagination": "Pagination",
  "Progress": "Progrès",
  "Tooltip": "Info-bulle",
  "CheckLocation": "Vérifier l'emplacement",
  "Slider": "Glissière",
  "Date Time Picker": "Sélecteur de date et d'heure",
  "Select": "Sélectionner",
  "Switch": "Commutateur",
  "Checkbox": "Case à cocher",
  "Radio": "Radio",
  "Textarea": "Textarea",
  "Rating": "Évaluation",
  "File Input": "Entrée de fichier",
  "Form Layout": "Disposition du formulaire",
  "Form Validation": "Validation de formulaire",
  "Charts": "Graphiques",
  "Apex Chart": "Graphique Apex",
  "Chartjs": "Chartjs",
  "Account Settings": "Paramètres du compte",
  "User Profile": "Profil de l'utilisateur",
  "FAQ": "FAQ",
  "Pricing": "Tarification",
  "List": "liste",
  "Edit": "Éditer",
  "Nav Levels": "Niveaux de navigation",
  "Level 2.1": "Niveau 2.1",
  "Level 2.2": "Niveau 2.2",
  "Level 3.1": "Niveau 3.1",
  "Level 3.2": "Niveau 3.2",
  "Raise Support": "Augmenter le soutien",
  "Documentation": "Documentation",
  "Dashboards": "Tableaux de bord",
  "Analytics": "Analytique",
  "Apps & Pages": "Applications et pages",
  "Email": "Email",
  "Chat": "Bavarder",
  "Invoice": "Facture d'achat",
  "Preview": "Aperçu",
  "Add": "Ajouter",
  "User": "Utilisateur",
  "View": "Vue",
  "Login v1": "Connexion v1",
  "Login v2": "Connexion v2",
  "Login": "Connexion",
  "Register v1": "S'inscrire v1",
  "Register v2": "S'inscrire v2",
  "Register": "S'inscrire",
  "Forget Password v1": "Oubliez le mot de passe v1",
  "Forget Password v2": "Oubliez le mot de passe v2",
  "Forgot Password v1": "Oubliez le mot de passe v1",
  "Forgot Password v2": "Oubliez le mot de passe v2",
  "Forgot Password": "Oubliez le mot de passe",
  "Reset Password v1": "Réinitialiser le mot de passe v1",
  "Reset Password v2": "Réinitialiser le mot de passe v2",
  "Reset Password": "Réinitialiser le mot de passe",
  "Miscellaneous": "Divers",
  "Coming Soon": "Bientôt disponible",
  "Not Authorized": "Pas autorisé",
  "Under Maintenance": "En maintenance",
  "Error": "Erreur",
  "Statistics": "Statistiques",
  "Card Actions": "Actions de la carte",
  "Actions": "Actions",
  "Access Control": "Contrôle d'accès",
  "User Interface": "Interface utilisateur",
  "CRM": "CRM",
  "eCommerce": "commerce électronique",
  "Icons": "Icône",
  "Chip": "Ébrécher",
  "Dialog": "Dialogue",
  "Expansion Panel": "Panneau d'extension",
  "Combobox": "Boîte combo",
  "Textfield": "Champ de texte",
  "Range Slider": "Curseur Gamme",
  "Menu": "Menu",
  "Snackbar": "Casse-croûte",
  "Tabs": "Onglets",
  "Form Elements": "Éléments de formulaire",
  "Form Layouts": "Dispositions de formulaire",
  "Authentication": "Authentification",
  "Page Not Found - 404": "Page introuvable - 404",
  "Not Authorized - 401": "Non autorisé - 401",
  "Server Error - 500": "Erreur de serveur - 500",
  "2": "2",
  "days":"jours",
  "freeTrialMsg":"L'essai expire dans",
  "expireMsg": "Essai expiré",
  "Forms": "Formes",
  "Timeline": "Chronologie",
  "Disabled Menu": "Menu désactivé",
  "Help Center": "Centre d'aide",
  "Verify Email": "Vérifier les courriels",
  "Verify Email v1": "Vérifier l'e-mail v1",
  "Verify Email v2": "Vérifier l'e-mail v2",
  "Two Steps": "Deux étapes",
  "Two Steps v1": "Deux étapes v1",
  "Two Steps v2": "Deux étapes v2",
  "Organization": "Organisation",
  "organization": "Organisation",
  "addNewOrg": "Ajouter une nouvelle Organisation",
  "changeLang": "Changer de langue",
  "profile1": "Profil",
  "logout": "Déconnexion",
  "Timesheet": "Feuille de temps",
  "messageBoard" : "Tableau de messages",
  "Timeoff": "Congé",
  "Achievement": "Réalisations",
  "Edit Organization": "Modifier l'organisation",
  "Timer Apps": "Applications de chronométrage",
  "Department": "Département",
  "Overview": "Aperçu",
  "Billing History": "Historique de facturation",
  "Project & Task Report": "Rapport de projet et de tâches",
  "Attendance Report": "Rapport de présence",
  "Job Sites": "Sites de travail",
  "Time off": "Temps libre",
  "deletedAcc": "Ce compte a été supprimé.",
  "Manualedit": "Modification manuelle de l'heure",
  "Profile Picture": "Photo de profil",
  "Primary Information": "Informations principales",
  "About": "À propos",
  "About my job": "À propos de mon travail",
  "Interests & Hobbies": "Intérêts et loisirs",
  "Personal Information": "Informations personnelles",
  "Emergency contact": "Contact d'urgence",
  "Job Details": "Détails du travail",
  "Banking Information": "Informations bancaires",
  "Identity Information": "Informations d'identité",
  "Deployed":"Déployé",
  "Yet to Start":"Pas encore commencé",
  "In Progress":"En cours",
  "On Hold":"En attente",
  "Archived":"Archivé",
  "Canceled":"Annulé",
  "Open":"Ouvert",
  "In Discussion":"En discussion",
  "In Development":"En développement",
  "QA Review":"Revue QA",
  "Ready For Test":"Prêt pour le test",
  "Need Help":"Besoin d'aide",
  "Re-Open":"Rouvrir",
  "Invalid":"Invalide",
  "Ready for deployment":"Prêt pour le déploiement",
  "Enabled": "Activé",
  "Disabled": "Désactivé",
  "NOT LOGGED-IN YET": "PAS ENCORE CONNECTÉ",
  "NOT WORKING": "NE FONCTIONNE PAS",
  "WORKING": "FONCTIONNE",
  "$vuetify": {
    "badge": "Badge",
    "pagination": {
      "ariaLabel": {
        "root": "racine",
        "previous": "précédente",
        "next": "suivante",
        "currentPage": "page actuelle",
        "page": "page"
      }
    },
    "input": {
      "clear": "dégager",
      "appendAction": "ajouter une action",
      "prependAction": "préfixer l'action"
    },
    "fileInput": {
      "counterSize": "Taille du compteur"
    },
    "rating": {
      "ariaLabel": {
        "item": "Objet"
      }
    }
  },
  "validators":{
    "requiredValidator": "Ce champ est obligatoire",
    "emailValidator": "Le champ Email doit être un email valide",
    "passwordValidator": "Utilisez 6 caractères ou plus avec un mélange de lettres, de chiffres et de symboles (!,#,$,%,&,*,-,@)",
    "confirmedValidator": "Les deux mots de passe doivent correspondre",
    "betweenValidator":{
      "enterNumberbetween": "Entrez le nombre entre",
      "and": "et",
      "the": "La"
    },
    "integerValidator": "Ce champ doit être un entier",
    "regexValidator": "Le format du champ Regex n'est pas valide"
  },
  "LoginView": {
    "Login": "Connexion",
    "LoginBtn": "Procéder à la connexion",
    "RememberMe": "Souviens-toi de moi",
    "ForgotPassword": "Mot de passe oublié",
    "Email": "Adresse e-mail *",
    "Password": "Mot de passe*"
  },
  "authForgotPassword": {
    "recoverPassword": "Récupérer votre mot de passe",
    "sendResetLink": "Envoyer le lien de réinitialisation",
    "backToLogin": "Revenir à la connexion"
  },
  "authMailConfirm":{
    "resetYourPassword": "réinitialisez votre mot de passe",
    "confirmationEmail": "Un e-mail de confirmation a été envoyé à",
    "checkYourInbox": "Vérifiez votre boîte de réception et cliquez sur le lien 'réinitialiser le mot de passe' pour changer votre mot de passe"
  },
  "settings": {
    "help&support": "AIDE ET SUPPORT",
    "knowMore" : "En savoir plus",
    "notAddedYet" : "Non encore ajouté.",
    "general": {
      "projects":{
        "controlDefaultRole": "Contrôlez le rôle par défaut car les membres seront affectés par défaut au rôle sélectionné.",
        "financeSettingDesc": "Permettez à vos chefs de projet de consulter les données financières du projet.",
        "ProjectSettingDesc": "Permettez à vos chefs de projet de consulter les paramètres du projet.",
        "individualSetting": "RÉGLAGE INDIVIDUEL",
        "members": "Membres",
        "search": "Recherche",
        "membersRoleHelp&support" : "Déléguer des responsabilités au sein de vos projets en attribuant des rôles distincts aux membres de l'équipe, garantissant ainsi la clarté et l'exécution optimisée du projet. En savoir plus sur la manière de gérer les attributions basées sur les rôles pour une meilleure gestion de projet.",
        "manageProjIdHelp&Support" : "Attribuer des identifiants uniques aux projets, garantissant un suivi fluide, une catégorisation simplifiée et une gestion de projet efficace.",
        "manageTodoStatusHelp&Support" : "Améliorez la visibilité des tâches et le suivi de l'avancement grâce à la gestion des statuts des tâches. Surveillez les étapes des tâches, favorisez la responsabilité et atteignez les jalons du projet.",
        "projectMilestoneHelp&Support" : "Naviguez dans la progression du projet avec la gestion des jalons. Définissez des jalons clés, suivez les réalisations et restez sur la bonne voie pour des résultats de projet réussis.",
        "manageTaskIDHelp&Support" : "Attribuez des identifiants uniques aux tâches à faire, garantissant un suivi fluide, une catégorisation simplifiée et une gestion efficace des tâches."
      
      },
      "EditOrg": {
        "setIpAddress": "Définir l'adresse IP",
        "OrgDetails": "Détails des organisation",
        "selCurrency": "Sélectionnez la devise*",
        "selTimezone": "Sélectionnez le fuseau horaire",
        "Date format": "Format de date",
        "Save": "Sauvegarder",
        "Max file size: 2MB": "Taille maximale du fichier : 2 Mo",
        "vat/gst": "Numéro de TVA/TPS*",
        "vatGstIN": "Je veux une facture TVA / GSTIN",
        "companyName": "Nom de l'entreprise",
        "companyAddress": "Company Address",
        "taxId": "Numéro d'identification fiscale",
        "start-week": "Commencer la semaine le*",
        "selectTimezone": "Sélectionnez le fuseau horaire",
        "vatBusiness": "TVA/TPS Nom de l'entreprise*",
        "editOrgHelp&support" : "Gérez et mettez à jour les détails de votre organisation pour refléter son identité, garantissant des informations précises et des opérations fluides. En savoir plus sur la personnalisation du profil de votre organisation pour une gestion sans heurts.",
        "setMemberRateHelp&Support" : "Définissez les tarifs de facturation, optimisez les flux de revenus et alignez vos services sur les attentes des clients. Obtenez plus d'informations sur la configuration des tarifs de facturation qui reflètent la valeur de vos services.",
        "memberTimeZoneHead" : "FUSEAU HORAIRE DES MEMBRES",
        "memberTimeZoneHelp&Support" : "Collaboration mondiale en configurant les fuseaux horaires individuels, garantissant une communication fluide et des efforts coordonnés à travers des emplacements divers. En savoir plus sur l'ajout de membres à plusieurs fuseaux horaires pour un travail d'équipe efficace.",
        "membersWeekOffHead" : "SEMAINE DES MEMBRES",
        "membersWeekOffHelp&Support" : "Une meilleure planification opérationnelle en définissant des jours de repos personnalisés pour les membres, favorisant le bien-être et une programmation efficace des employés. En savoir plus sur la fourniture de jours de repos pour un meilleur équilibre entre vie professionnelle et vie personnelle, ainsi que pour une productivité accrue.",
        "BILL RATE ($)": "TAUX DE FACTURATION ($)",
        "BILL RATE (₹)": "TAUX DE FACTURATION (₹)",
        "BILL RATE": "TAUX DE FACTURATION"
      },
      "TimerApp": {
        "ScreenshotTimerHead": "TIMER DE CAPTURE D'ÉCRAN",
        "ScreenshotTimerHelp&Support" : "Capturer l'avancement du travail avec un minuteur de capture d'écran. Définissez des intervalles de minuterie de 5, 10 ou 15 minutes selon les besoins de votre projet. En savoir plus sur la personnalisation d'un minuteur de capture d'écran pour répondre aux besoins spécifiques de votre entreprise.",
        "IdleNotesHead" : "NOTES EN VEILLE",
        "IdleNotesHelp&Support" : "Optimisez le suivi du temps avec des notes d'inactivité. Enregistrez les pauses ou les distractions pour visualiser les habitudes de travail afin d'analyser la productivité détaillée des équipes. En savoir plus sur l'ajout de notes de temps pour obtenir un aperçu des habitudes de travail et optimiser l'efficacité.",
        "DefineBreaksHead" : "DÉFINIR DES PAUSES",
        "DefineBreaksHelp&Support" : "Maintenez un flux de travail équilibré en définissant des pauses structurées. Favorisez des pauses revigorantes tout en vous alignant sur les besoins opérationnels de votre équipe. En savoir plus sur la définition d'intervalles de pause pour promouvoir le bien-être et des horaires de travail flexibles.",
        "BreakTimeLimitHead" : "LIMITATION DE TEMPS DE PAUSE",
        "BreakTimeLimitHelp&Support" : "Définissez des intervalles de pause prédéfinis pour garantir une productivité optimale et éviter des pauses prolongées afin d'améliorer les taux d'achèvement des tâches. Explorez davantage la définition des temps de pause pour maintenir un environnement de travail sain et accroître l'efficacité.",
        "TrackingModeHelp&Support" : "Choisissez entre les modes « Visible » et « Stealth » pour surveiller le temps, offrant ainsi une flexibilité dans le suivi et s'adaptant à différentes routines de travail. En savoir plus sur le passage entre les modes de suivi pour répondre à vos préférences et à votre environnement de travail."
     },
     "Department": {
      "DepartmentHelp&Support" : "Créez des départements pour organiser vos équipes. Définissez les responsables de département, approuvez les feuilles de temps, et bien plus encore pour un cadre opérationnel productif. Apprenez-en davantage sur la création de départements pour une meilleure attribution des tâches, collaboration et gestion."
     },
     "Members" : {
      "memberIdHelp&Support" : "Configurez des identifiants uniques de membre pour améliorer l'identification des utilisateurs. Optimisez la navigation dans le système avec des identifiants de membre personnalisés.",
      "desigantionHelp&Support" : "Créez les différents titres de poste ou désignations et attribuez-les aux employés. Cela vous aidera à gérer les ressources de manière hiérarchique.",
      "profileSettingsHelp&Support" : "Définissez la confidentialité et les autorisations. Si vous souhaitez que les employés voient le profil d'autres employés."
     },
     "expense": {
      "expanseText": "Définir la catégorie de dépense ou Activer/Désactiver la catégorie de dépense pour tous les membres de l'organisation",
      "addCategories": "Ajouter une catégorie",
      "searchPlaceholder": "rechercher une catégorie",
      "categoryName": "Nom de la catégorie",
      "statusText": "Statut",
      "deleteText": "Supprimer",
      "defineCategory": "DÉFINIR LA CATÉGORIE DE DÉPENSE",
      "DefineExpenseHelp&Support": "Consolidez toutes les dépenses liées au projet en un seul endroit afin que les organisations puissent mieux contrôler leurs finances, garantir la transparence et améliorer l'efficacité de la gestion globale du projet.",
      "saveText": "Enregistrer",
      "sureToDelete": "Êtes-vous sûr de vouloir supprimer cette catégorie ?"
    },
      "memberWeeklyOff": "Jours de repos hebdomadaires des membres",
      "memberTimezone": "Fuseau horaire des membres",
      "setBillingRate": "Définir les tarifs des membres"
    },
    "feature": {
      "activity": {
        "updateVisiblityStatus": "Le statut a été modifié avec succès.",
        "hideActivity": "Masquer le menu d'activité pour les utilisateurs (y compris la capture d'écran, les applications et les URL)",
        "noData": "Il n'y a aucun membre présent en ce moment!",
        "hideScreenshot" : "Arrêter d'afficher les notifications d'application pour prendre des captures d'écran",
        "productivity-setting": "Suivre les heures productives réelles des membres en définissant des applications/URL productives et unproductives",
        "Disable": "Désactiver",
        "Enable": "Activer",
        "DeleteScreenshot": "Autoriser les gestionnaires et les propriétaires à supprimer des captures d'écran.",
        "INDIVIDUAL SETTING": "RÉGLAGE INDIVIDUEL",
        "MEMBERS": "MEMBRES",
        "controlSSFrequency": "Contrôlez la fréquence des captures d'écran.",
        "SsBlur": "Contrôlez si l'application de bureau floute les captures d'écran pour des raisons de sécurité et de confidentialité.",
        "TrackUrl": "Contrôlez si les noms des applications utilisées et les URL visitées sont suivis",
        "Ok": "D'accord",
        "Track Apps & URLs both": "Suivre les applications et les URL à la fois",
        "Track URLs only": "Suivre les URL uniquement",
        "Track Apps only": "Suivre les applications uniquement",
        "OFF": "DÉSACTIVÉ",
        "Add Unproductive": "Ajouter improductif",
        "Add productive": "Ajouter productif",
        "URLs": "URL",
        "Apps": "applications",
        "All Teams": "Toutes les équipes",
        "Search": "Recherche",
        "hideActivityMenuHelp&Support" : "Workstatus donne aux propriétaires d'équipe l'avantage de masquer le menu des activités pour leurs utilisateurs. En savoir plus sur la façon de masquer le menu des activités pour réduire les distractions et se concentrer sur la gestion des tâches.",
"screenshotNotificationHelp&Support" : "Restez informé avec des alertes en temps réel sur les notifications de captures d'écran. Restez responsable et transparent dans votre processus de travail. En savoir plus sur la façon de rester informé sur les activités de captures d'écran pour une transparence complète.",
"trackAppsUrlHelp&Support" : "Analysez les modèles de travail avec des informations approfondies sur l'utilisation des applications et des sites web pour optimiser la productivité. En savoir plus sur les outils de surveillance des sites web et des applications pour obtenir des informations précieuses pour une meilleure gestion du temps.",
"blurScreenshotHelp&Support" : "Assurez la confidentialité des données avec le flou des captures d'écran. Protégez les informations sensibles dans les captures d'écran, en équilibrant la responsabilité et la confidentialité. En savoir plus sur les fonctionnalités avancées de la surveillance des captures d'écran pour maintenir les normes de confidentialité les plus élevées.",
"screenshotFrequencyHelp&Support" : "Personnalisez la fréquence de capture des captures d'écran à 1x, 2x, 3x aux intervalles de 5, 10 et 15 minutes. En savoir plus sur la gestion et la modification de la fréquence des captures d'écran pour correspondre à la dynamique de vos tâches.",
"deleteScreenshotHelp&Support" : "Maintenez le contrôle des données en supprimant les captures d'écran. Organisez les enregistrements et assurez une vue d'ensemble claire des activités capturées. En savoir plus sur la gestion de l'historique de vos captures d'écran pour une tenue de registre précise.",
"productivitySettingsHelp&Support" : "Suivez les heures productives réelles des membres de l'équipe en définissant des applications/URL productives et non productives. Explorez davantage l'optimisation de vos outils de productivité pour les aligner sur vos préférences."
      },
      "timesheet":{
        "modifyTime":"Permettez aux membres de l'équipe de modifier leur temps manuellement.",
        "Yes":"Oui",
        "No":"Non",
        "individualSetting":"Réglage individuel",
        "MEMBERS":"MEMBRES",
        "approvalTimesheet":"Permettez aux membres de l'équipe de modifier leurs paramètres de feuille de temps d'approbation",
        "toggle1":"Ne nécessite pas l'approbation",
        "toggle2":"Seule feuille de temps manuelle",
        "toggle3":"Feuille de temps manuelle et automatique",
        "popupApproval":"Ce changement de paramètre nécessitera une rechargement de la page afin que la modification puisse être efficace à l'échelle du système pour toutes les données de feuille de temps de membre.Veuillez appuyer sur OK pour continuer?",
        "requireReason":"Obliger les membres de l'équipe à fournir une raison lors de la modification de leur temps.",
        "faceDetection":"Demandez aux membres de l'équipe de prendre des selfies et de faire face à la détection chaque fois que la minuterie est démarrée ou arrêtée",
        "save":"sauvegarder",
        "facePer":"Combien% est autorisé?",
        "tooltipMessage":"Nous allons faire correspondre l'image selfie avec des images de profil de l'utilisateur et permettre au-dessus de% entré pour la correspondance",
        "manageLimits":"Autoriser la feuille de temps après la limite?",
        "togglemsg1":"Arrêtez la minuterie et montrez l'alerte",
        "togglemsg2":"Laissez la minuterie continuer normalement",
        "weeklyTimesheet":"Autoriser la feuille de temps hebdomadaire",
        "OK":"D'ACCORD",
        "modifyTimeHelp&Support": "Encouragez la flexibilité en permettant aux membres de l'équipe de modifier manuellement leur temps. Informez-vous davantage sur le processus de modification du temps pour des rapports complets et précis.",
        "approvalTimesheetHelp&Support" : "Simplifiez le flux de travail avec l'approbation des feuilles de temps en permettant aux membres de l'équipe de modifier leurs paramètres d'approbation des feuilles de temps. En savoir plus sur l'approbation des feuilles de temps pour une meilleure responsabilité et des procédures d'approbation rationalisées.",
"requireReasonhelp&Support" : "Les membres de l'équipe doivent soumettre une raison valide lors de la modification de leurs entrées de temps. En savoir plus sur l'ajout de raisons pour les demandes de régularisation afin de garantir une gestion précise des enregistrements.",
"faceDetectionhelp&Support" : "Ajoutez de la sécurité à votre suivi de présence pour garantir une vérification d'identité fiable, rationaliser le contrôle d'accès et renforcer l'intégrité opérationnelle. En savoir plus sur le système de vérification par selfie pour renforcer le processus de gestion de la présence.",
"manageLimitsHelp&Support" : "Personnalisez les contrôles d'accès, gérez les seuils d'utilisateur et améliorez les protocoles de sécurité pour la vérification d'identité. En savoir plus sur la personnalisation de l'accès pour garantir des opérations sécurisées et contrôlées.",
"weeklyTimesheetsHelp&Support" : "Téléchargez le rapport hebdomadaire des feuilles de temps pour les individus ou les équipes et envoyez-le à un membre sélectionné. En savoir plus sur la gestion et l'utilisation des feuilles de temps en ligne pour optimiser les processus de paie."
      },
      "featureTabName":{
        "Timesheet":"Emploi du temps",
        "Schedules":"Des horaires",
        "modifyTime":"Modifier le temps",
        "approvalTimesheet":"Feuille de temps d'approbation",
        "requireReason":"Nécessitent des raisons",
        "faceDetection":"Détection facial",
        "manageLimits":"Gérer les limites",
        "weeklyTimesheets":"Feuilles de temps hebdomadaires",
        "shiftAlerts":"Déplacer des alertes",
        "minimumShiftHours":"Heures de décalage minimum",
        "Activity": "Activité",
        "Timeoff": "Temps libre",
        "Jobsite": "Chantier",
        "Achievement": "Réalisation",
        "Notifications": "Avis",
        "Features Settings": "Paramètres des fonctionnalités"
      },
      "sidebar": {
        "hideActivity": "Masquer le menu d'activité",
        "SsNotification": "Notifications de capture d'écran",
        "trackApps": "Suivre les applications et les URL",
        "blurSs": "Captures d'écran Flou",
        "SsFreq": "Fréquence des captures d'écran",
        "delSs": "Supprimer les captures d'écran",
        "productivity": "Paramètres de productivité"
      },
      "schedule": {
        "receiveAlertNotification": "Contrôler qui reçoit une alerte à propos d'un membre",
        "Management": "Gestion",
        "None": "Aucune",
        "Both": "Les deux",
        "shiftHours": "Contrôler les heures de travail minimales d'un membre",
        "shiftAlertHelp&Support" : "Restez ponctuel et informé avec les alertes de changement. Recevez des notifications opportunes sur les prochains changements, garantissant une planification fluide et une amélioration de la gestion du temps. En savoir plus sur la façon de définir des alertes de planning pour améliorer la ponctualité.",
"shiftHourHelp&Support" : "Définissez des heures de travail minimales pour garantir une utilisation optimale des ressources et maintenir une charge de travail équilibrée pour l'allocation des tâches. En savoir plus sur la définition des heures de travail minimales pour rationaliser la distribution des tâches et améliorer l'équilibre opérationnel."
      },
      "timeOff": {
        "noMembers": "Il n'y a aucun membre présent en ce moment!",
        "timeOffPolicies": "POLITIQUES DE CONGÉS",
        "accrualSchedule": "CALENDRIER DE COMPTABILISATION",
        "members": "MEMBRES",
        "policy": "POLITIQUE",
        "View": "Voir",
        "Edit": "Modifier",
        "Archive": "Archive",
        "Remove": "Retirer",
        "Unarchive": "Désarchiver",
        "addPolicy": "Ajouter une nouvelle politique",
        "addPolicyMsg": "Vous pouvez ajouter la nouvelle politique de congés pour toute l'organisation OU pour des utilisateurs spécifiques au sein de l'organisation à partir d'ici. Afficher la politique : consultez les détails de la politique individuelle ici.",
        "editPolicyMsg": "Vous pouvez modifier la politique de congés pour l'ensemble de l'organisation OU pour des utilisateurs spécifiques au sein de l'organisation à partir d'ici.",
        "generalInfo": "informations générales",
        "back": "Dos",
        "policyName": "Nom de la politique*",
        "memberslabel": "Membres",
        "automaticallyAddnewMem": "Ajouter automatiquement de nouveaux membres à cette politique",
        "scheduleOfAccrual": "Calendrier d'accumulation*",
        "maxAccrualAmount": "Montant maximum d'accumulation",
        "amountAccured": "Montant accumulé",
        "allowNegBal": "Autoriser le solde négatif*",
        "reqApproval": "Nécessite une approbation*",
        "balanceRolls": "Le solde est reporté annuellement*",
        "paidOrUnpaid": "Payé ou non payé*",
        "viewTimeOff": "Afficher les congés",
        "viewTimeOffMsg": "Consultez les détails de la demande de congé individuelle à partir d'ici.",
        "basicInfo": "Informations de base",
        "policyPaidUnpaid": "Police payée/non payée",
        "escalation": "ESCALADE",
        "escalationMsg": "Contrôler la demande de congé d'escalade.",
        "idleNoteText": "Activer, désactiver la marque comme obligatoire et la marque comme non obligatoire pour les notes inactives.",
        "breakText": "Définir la pause ou Activer, désactiver la pause pour tous les membres de l'organisation",
        "breakName":"PAUSE",
        "addBreak": "Ajouter une pause",
        "status": "STATUT",
        "hours": "Heures",
        "accruedHours" : "heure(s) acquise(s) pour chaque",
        "workedHours" : "heures travaillées",
        "save" : "Enregistrer",
        "ok" : "D'accord",
        "hoursText": "heures travaillées",
        "accuredHours": "heure(s) accumulée(s) pour chaque",
        "timeOffPoliciesHelp&Support" : "Créez vos politiques de congé qui vous aideront à gérer les congés des employés et leur permettront de prendre des temps libres en fonction des politiques que vous créerez ici.",
        "escalationHelp&Support" : "Définissez des voies d'escalade et des procédures pour relever rapidement les défis et maintenir des opérations fluides. Apprenez-en davantage sur la mise en place de protocoles d'escalade pour garantir une résolution rapide des problèmes et favoriser des flux de travail ininterrompus.",
        "editpolicy": "Modifier la politique" 
      },
      "jobsite":{
        "jobsiteTabHead": "Restreindre la minuterie aux chantiers",
        "jobsiteTabText": "Autoriser uniquement les membres à suivre leur temps lorsqu'ils sont sur un chantier",
        "theadName": "NOM",
        "restrictTimerHelp&Support" : "Limitez les employés à utiliser leur minuterie uniquement sur le chantier de travail assigné.",
"enterExitNotificationHelp&Support" : "Restez informé et en contrôle avec les notifications d'entrée/sortie. Recevez des alertes en temps réel lorsque les membres de l'équipe entrent ou sortent de lieux spécifiques. Découvrez comment les notifications d'entrée et de sortie peuvent vous maintenir connecté et informé."
      },
      "achievement":{
        "efficiency":{
          "achievementTabTitle": "EFFICACITÉ PRO",
          "achievementTabText": "Ce badge de réussite est remis aux membres chaque jour où leur activité atteint l'objectif d'activité. Ils peuvent gagner ce badge plusieurs jours de suite pour créer des séquences chaudes",
          "achievementDefault": "DÉFAUT",
          "achievementActivity": "Objectif d'activité",
          "achievementEfficiencyText": "Atteindre l'objectif d'activité chaque jour",
          "achievementButton": "S'applique à tous",
          "achievementTitle": "Efficacité Pro",
          "efficienyProHelp&Support" : "Débloquez des outils avancés et des idées pour optimiser votre flux de travail, rationaliser les tâches et atteindre une productivité maximale. En savoir plus sur la transformation de vos équipes en professionnels pour propulser votre travail en avant."
        },
        "productivity":{
          "productivityTabText": "Ce badge de réussite est remis aux membres chaque semaine où ils atteignent l'objectif de tâches hebdomadaires. Ils peuvent gagner ce badge plusieurs semaines de suite pour créer des séquences chaudes.",
          "productivityChamp": "Champion de la productivité",
          "productivityChampCaps": "CHAMPION DE LA PRODUCTIVITÉ",
          "week-to-dos": "Objectifs de tâches hebdomadaires",
          "productivityText":"Atteindre l'objectif de tâches terminées chaque semaine",
          "weekly-to-dos": "OBJECTIFS À DOS HEBDOMADAIRES",
          "todos": "À faire",
          "productivityHelp&Support" : "Devenez un champion de la productivité avec notre solution riche en fonctionnalités. Augmentez votre rendement, gérez vos tâches de manière transparente et atteignez efficacement vos objectifs. En savoir plus sur la manière de devenir un champion de la productivité pour renforcer votre parcours productif."
        },
        "timeHero": {
          "timeHeroTitle": "HÉROS DU TEMPS",
          "timeHeroTabText": "Ce badge de réussite est remis aux membres chaque semaine où leurs heures atteignent l'objectif d'heures hebdomadaires. Ils peuvent gagner ce badge plusieurs semaines de suite pour créer des séquences chaudes.",
          "timeHeroText" : "Atteindre l'objectif du nombre total d'heures travaillées chaque semaine",
          "timeHeroActivityGoal": "Objectif d'activité",
          "timeHeroActivityWeekly": "OBJECTIF D'HEURES HEBDOMADAIRES",
          "timeHero": "Héros du temps",
          "timeHeroHelp&Support" : "Transformez-vous en un Héros du Temps avec nos fonctionnalités innovantes. Prenez le contrôle de votre temps, conquérez les tâches et excellez dans vos efforts avec une productivité renforcée. En savoir plus sur les paramètres de réalisation pour devenir un héros du temps."
        },
        "WorkSummary": {
          "dailyWorkSummaryText": "Gérez qui recevra les notifications par e-mail de résumé du travail quotidien",
          "weeklyWorkSummaryText": "Gérer qui recevra les notifications par e-mail de résumé hebdomadaire du travail",
          "dailyWorkSummaryTitle": "Résumé du travail quotidien",
          "weeklyWorkSummaryTitle": "Résumé du travail hebdomadaire",
          "dailyWorkSummaryHelp&Support" : "Recevez des résumés concis de vos tâches quotidiennes, de vos réalisations et de vos jalons pour une planification et une réflexion efficaces. En savoir plus sur le résumé quotidien du travail pour façonner vos succès quotidiens.",
"weeklyWorkSummaryHelp&Support" : "Recevez des résumés concis de vos tâches hebdomadaires, de vos réalisations et de vos jalons pour une planification et une réflexion efficaces. En savoir plus sur le résumé quotidien du travail pour façonner vos succès quotidiens."
        }
      }
    },
    "organisation":{
      "orgName": "Organisations",
      "requestOrgBtn": "Organisation de la demande",
      "addOrgBtn": "Ajouter une nouvelle organisation",
      "activeOrganisation": "ORGANISATIONS ACTIVES",
      "logo": "LOGO",
      "tableName": "NOM",
      "emailId": "ID E-MAIL",
      "teamsize": "TAILLE DE L'ÉQUIPE",
      "industry": "INDUSTRIE",
      "managers": "GESTIONNAIRES",
      "editOrg": "Modifier l'organisation",
      "archiveOrg": "Organisation des archives",
      "urarchiveOrg": "Désarchiver l'organisation",
      "addNewOrgTitle": "Ajouter une nouvelle politique",
      "addOrgText": "Vous pouvez ajouter une nouvelle organisation à partir d'ici - cela vous donnera la flexibilité de gérer plusieurs organisations à partir d'un seul compte.",
      "companydetails": "Détails de l'entreprise",
      "companyName":"Nom de l'entreprise*",
      "selectIndustry": "Sélectionnez l'industrie*",
      "addTeamSize": "Sélectionnez la taille de l'équipe*",
      "goal": "l' objectif",
      "activetab": "ACTIF",
      "archive": "Archiver",
      "orgId": "ID ORGANISATION",
      "nodata": "Aucune donnée d'organisation"
    }
  },
  "Getting-started": {
    "createOrgCheckOne": "Créez-vous une nouvelle organisation ou rejoignez-vous un compte d'entreprise existant?"
  },
  "commonConst": {
    "Users": "Utilisateurs",
    "Off": "Désactivé",
    "On": "Sur",
    "none": "aucune",
    "timeZoneNote": "Remarque : L'heure indiquée correspond au fuseau horaire de l'utilisateur sélectionné."
  },
  "Project": {
    "active-project": {
        "ACTIVE PROJECTS": "PROJETS ACTIFS",
        "PROJECT NAME": "NOM DU PROJET",
        "MANAGERS": "GESTIONNAIRES",
        "USERS": "UTILISATEURS",
        "CLIENTS": "CLIENTS",
        "TYPE": "TAPER",
        "STATUS": "STATUT",
        "Edit Project": "Modifier le projet",
        "Archive Project": "Archiver le projet",
        "New Project": "Nouveau projet",
        "Active": "Actif",
        "moveProject": "Déplacer le projet",
        "deleteProject": "Supprimer le projet",
        "addNewProject": "Ajouter un nouveau projet",
        "addText": "Ici, vous pouvez créer des projets et les attribuer aux bonnes ressources et commencer le suivi.",
        "projectBasicInfo": "Informations de base",
        "Save": "Enregistrer",
        "Manager": "Gestionnaire",
        "moveOrgText": "Déplacer le projet vers l'organisation",
        "moveTodo": "Déplacer toutes les tâches associées à ce projet vers l'organisation sélectionnée",
        "orgLabel": "Sélectionner l'organisation pour le projet",
        "cantMoveOrg": "Vous ne pouvez pas déplacer de projets car vous êtes associé à une seule organisation.",
        "confirmArchive": "Êtes-vous sûr de vouloir archiver ce projet?",
        "archiveCondition": "L'archivage du projet les désactivera, et les projets et les tâches seront supprimés de toutes les applications de la plateforme. Souhaitez-vous toujours continuer?",
        "backupAndDelete": "Sauvegarder et supprimer",
        "Delete": "Supprimer",
        "deleteCondition": "Vous ne pourrez pas revenir en arrière!",
        "noActivePoject": "Il n'y a pas de projets actifs!",
        "yes": "Oui",
        "no": "Non",
        "totalProject": "PROJETS TOTAUX",
        "yetToStart": "À DÉMARRER",
        "inProgress": "EN COURS",
        "onHold": "EN ATTENTE",
        "completed": "TERMINÉ",
        "archived": "ARCHIVÉ",
        "cancelled": "ANNULÉ",
        "projectName": "NOM DU PROJET",
        "client": "CLIENTS",
        "status": "STATUT",
        "progress": "PROGRÈS",
        "members": "MEMBRES",
        "viewProject": "Voir le projet",
        "markAsCompleted": "Marquer comme terminé",
        "deleteMilestone": "Supprimer l'étape importante",
        "completedTodo": "Tâches terminées",
        "budgeted": "Budget",
        "invested": "Investi",
        "hrs": "Hrs",
        "overbudgeted": "Surbudget",
        "isBillable": "Est facturable",
        "goBackToProject": "Retourner au projet",
        "move": "Déplacer",
        "chooseGroup": "Choisir un Groupe",
        "setBudget": "Définir le budget",
        "nameErr": "Les caractères spéciaux ne sont pas acceptés",
        "nameError": "Le nom du projet ne doit pas dépasser 50 caractères",
        "projectManager": "Chef de Projet",
        "startDateErr": "La date de début doit être antérieure à la date de fin.",
        "descErr": "La description ne doit pas dépasser 400 caractères",
        "addProjDecErr": "La description ne doit pas dépasser 2000 caractères",
        "hoursLimit": "Vous pouvez saisir des heures jusqu'à 99999999",
        "numberLimit": "Le nombre doit être compris entre 10 et 100.",
        "budget%": "% du budget est investi",
        "searchClient": "Rechercher un client",
        "projectStatus": "Statut du Projet",
        "selectProjectMember": "les Membres du Projet",
        "addTodoGroup": "Add Todo Group",
        "addDesc": "Ajouter une description",
        "addBudget": "Ajouter un budget",
        "setBillRate" : "Définir le taux de facturation"
    },
    "archive-project": {
        "noArchiveProject": "Il n'y a pas de projets archivés!",
        "Unarchive Project": "Désarchiver le projet",
        "Projects": "Projets",
        "ProjectName": "Nom du projet",
        "Archive": "Archivé"
    }
  },

  "dashboard": {
    "team": {
      "newTeam": "Ajouter une équipe",
      "searchTeam": "Équipe de recherche",
      "filters": "Filtres",
      "project": "Projet",
      "selectAProject": "Sélectionnez un projet",
      "apply": "Appliquer",
      "clear": "Clair",
      "teamName": "NOM DE L'ÉQUIPE",
      "teamLead": "CHEF D'ÉQUIPE",
      "teammates": "COÉQUIPIERS",
      "projects": "PROJETS",
      "noTeams": "Il n'y a pas d'équipes !",
      "editTeamText": "Modifier l'équipe",
      "removeTeam": "Supprimer l'équipe",
      "newTeam1": "Nouvelle équipe",
      "addTeam": {
        "addNewTeams": "Ajouter de nouvelles équipes",
        "createTeamDeps": "Créez différentes équipes/services pour rationaliser votre flux de travail.",
        "teamName": "Nom de l'équipe*",
        "teammates": "Coéquipiers",
        "teamlead": "Chef d'équipe",
        "selectAll": "tout sélectionner",
        "none": "aucun"
      },
      "editTeam": {
        "editTeam": "Modifier l'équipe",
        "editTeamDeps": "Modifiez différentes équipes/services pour rationaliser votre flux de travail.",
        "generalInfo": "informations générales",
        "teammatesDet": "Détails des coéquipiers",
        "teamLeadDet": "Détails du chef d'équipe",
        "projectDet": "Détails des projets",
        "details": "Détails",
        "createdBy": "Créé par",
        "permissions": "Autorisations :",
        "approveTimesheet": "Approuver les feuilles de temps",
        "approveTimeoffReq": "Approuver les demandes de congés",
        "scheduleShift": "Planifier des quarts de travail",
        "continue": "Continuer",
        "cancel": "Annuler",
        "save": "Sauvegarder"
      }
    }
  },
  "todo": {
    "reportedBy":"Créé/Rapporté par",
    "Export": "Exporter",
    "Filter": "Filtre",
    "TO-DOs": "TO-DO",
    "todo": "À FAIRE",
    "Add To-Dos": "Ajouter des tâches",
    "noTodos": "Il n'y a pas de todo !",
    "TODO DETAILS": "DÉTAILS À FAIRE",
    "TodoGroupStatus":"Statut du groupe Todo",
    "MEMBERS": "MEMBRES",
    "START DATE": "DATE DE DÉBUT",
    "END DATE": "DATE DE FIN",
    "PRIORITY": "PRIORITÉ",
    "ATTACHMENTS": "ACCESSOIRES",
    "TYPE": "TAPER",
    "STATUS": "STATUT",
    "PROGRESS": "PROGRÈS",
    "Low": "Faible",
    "Medium": "Moyen",
    "High": "Haut",
    "View ToDo": "Afficher la tâche",
    "Edit ToDo": "Modifier la tâche",
    "Complete ToDo": "Tâche complète",
    "Delete ToDo": "Supprimer la tâche",
    "noTodo": "Il n'y a pas de choses à faire !",
    "selectAProj": "Sélectionnez un projet",
    "or": "ou",
    "searchProjs": "Rechercher des projets",
    "reopen": "Leo Penn et le sol",
    "addTodoGroup": "Ajouter un groupe de tâches",
    "showMore": "Afficher Plus",
    "showLess": "Afficher Moins",
    "moveToOtherGroup": "Déplacer vers un Autre Groupe",
    "moveErr": "Vous ne pouvez pas déplacer les tâches, car vous n'avez qu'un seul groupe de tâches.",
    "moveOtherGroup": "Déplacer la Tâche vers un Autre Groupe",
    "todoLowercase": "tâche à faire",
    "addTodo": {
      "newTodo": "Ajouter une nouvelle tâche",
      "addTodoText": "Ici, vous pouvez créer et attribuer des tâches à vos ressources et commencer à suivre leur progression.",
      "BI": "Informations de base",
      "OD": "Autres détails",
      "membName*": "Nom de membre*",
      "Cancel": "Annuler",
      "Continue": "Continuer",
      "startDate": "Start Date",
      "End Date": "Date de fin",
      "inputFileText": "Faites glisser et déposez le fichier ici",
      "browseFile": "Parcourir les fichiers",
      "Recurrence": "Récurrence",
      "Billable": "Facturable",
      "Save": "Sauvegarder",
      "recurrenceFrequency": "Fréquence de récurrence",
      "todoResult": "Résultats des Tâches à Faire",
      "addTodo": "Ajouter une tâche",
      "addTodoSideNavText": "Ici, vous pouvez créer et attribuer des tâches à vos ressources et commencer à suivre leur progression.",
      "backToGroups": "Retour à Todos",
      "groupText": "Le nom ne peut pas dépasser 255 caractères.",
      "saveTodo": "Enregistrer la Tâche",
      "additionalInfo": "Informations Supplémentaires",
      "endateErr": "La date de fin doit être supérieure à la date de début.",
      "estimatedHours": "Heures estimées",
      "rangeError": "Veuillez entrer une valeur entre 00:00 et 99999:59",
      "progress": "Progrès",
      "percRangeError": "Veuillez entrer une valeur entre 0 et 100",
      "addTodoGroup": "Groupe de Tâches*",
      "Expand": "Développer",
      "Collapse": "Réduire",
      "estimated": "Estimated",
      "rangeTImeErr": "Veuillez saisir une valeur supérieure à 00.01",
      "save&Add":"Enregistrer et ajouter",
      "todoGroupText": "Groupe de Tâches"
    },
    "editTodo": {
      "edit": "Modifier la tâche",
      "editText": "Ici, vous pouvez créer et attribuer des tâches à vos ressources et commencer à suivre leur progression.",
      "BI": "Informations de base",
      "OD": "Autres détails",
      "addMore": "Ajouter plus",
      "recurrenceText": "Créer la prochaine récurrence après la fermeture de la tâche en cours.",
      "nameErr": "Le nom ne peut pas dépasser 255 caractères.",
      "attachFile": "Attacher un Fichier",
      "investedHours": "Heures Investies",
      "lastEdited": "Dernière Modification",
      "createdBy": "Créé par et Heure",
      "parentTodoLabel": "Nom de la Tâche Parent",
      "todoName": "Nom de la Tâche*",
      "selectMembers": "Sélectionner les Membres*",
      "Assigné" : "Assigné*",
      "Assignés" : "Assignés*",
      "ASSIGNEES" : "CESSIONNAIRE(S)"
    },
    "viewTodo": {
      "view": "Afficher la tâche",
      "viewText": "Affichez les détails de chaque tâche ici et modifiez-les rapidement (si nécessaire).",
      "details": "Détails de la tâche",
      "projectName": "nom du projet*",
      "name": "Nom de la tâche",
      "memberName": "Nom de membre",
      "startDate": "Date de début",
      "endDate": "Date de fin",
      "Priority": "Priorité",
      "Low": "Faible",
      "Medium": "Moyen",
      "High": "Haut",
      "Status": "Statut",
      "Description": "Description",
      "noDescription": "Pas de description disponible",
      "Attachments": "Pièces jointes",
      "No Attachment": "Pas d'attachement",
      "Download All": "Tout télécharger",
      "frequency": "Fréquence récurrente*",
      "Daily": "Quotidienne",
      "Weekly": "Hebdomadaire",
      "Monthly": "Mensuelle",
      "Yearly": "Annuelle",
      "Once every": "Une fois tous les*",
      "End after": "Fin après*",
      "Edit To-do": "Modifier la tâche",
      "budgetoverview": "APERÇU DU BUDGET",
      "TodoStatus" : "ÉTAT DE LA TÂCHE",
      "AllToDoGroups" : "Tous les groupes de tâches à faire",
      "todos-worked": "TÂCHES EFFECTUÉES",
      "view-all": "Voir toutes les tâches",
      "projectID": "Identifiant du Projet",
      "client": "Client",
      "members": "Membres",
      "createdBy": "Créé par",
      "createdTime": "Heure de création",
      "totalTodo": "TOTAL DES TÂCHES À FAIRE",
      "due": "Échéance",
      "overdue": "En retard",
      "recurring": "Récurrent",
      "completed": "Terminé (%)",
      "todoType": "Type de Tâche",
      "selectTodo": "Sélectionner le Type de Tâche",
      "severity": "Gravité",
      "tags": "Tags",
      "nodata": "Aucune donnée trouvée !",
      "loadMore": "Charger plus",
      "todoType1": "Type de Tâche à Faire",
      "tag": "Étiquette",
      "selectPrioroty": "Sélectionner la Priorité",
      "selectoverdue": "Sélectionner les tâches en retard",
      "selectDue": "Sélectionner les tâches à venir",
      "selectStatus": "Sélectionner le Statut",
      "selectSeverity": "Sélectionner la gravité",
      "selectTags": "Sélectionner les tags",
      "searchProject": "Rechercher un Projet",
      "selectProject": "Sélectionner le Projet",
      "addTags": "Ajouter des tags",
      "attachment": "Pièce jointe",
      "overview": "Vue d'ensemble",
      "typeHere": "Tapez ici",
      "noDailyLimit": "Aucune limite quotidienne",
      "noWeekLimit": "Aucune limite de semaines"
    }
  },
  "addSingleLineTodo":{
    "placeholder":"Entrez le nom de la tâche",
    "pressEnterText":"Appuyez sur Entrée pour enregistrer",
    "assigneeTooltip":"Cessionnaire(s)",
    "startEndDateTooltip":"Date de début/fin",
    "priorityTooltip":"Définir la priorité",
    "taskListTooltip":"Liste de tâches",
    "typeTooltip":"Type de tâche",
    "enstimatedHoursPlaceholder":"HH : MM",
    "estimatedHoursTooltip":"Heures estimées",
    "estimatedHoursError":"Heures estimées entre 00h00 et 99999h59"
  },
  "workOrder": {
    "Work Orders": "Bons de travail",
    "+ Add a Work Order": "+ Ajouter un bon de travail",
    "weekly": "hebdomadaire",
    "Date Range": "Plage de dates",
    "noWorkOrders": "Il n'y a pas de bons de travail",
    "WORKORDER": "DEMANDE DE SERVICE",
    "ASSIGNEES": "CESSIONNAIRES",
    "ADDRESS": "ADRESSE",
    "BUDGET": "BUDGET",
    "NEXT JOB": "PROCHAIN ​​EMPLOI",
    "STATUS": "STATUT",
    "UNSCHEDULED": "NON PLANIFIÉ",
    "MISSED": "MANQUÉE",
    "noUpcomingJob": "Aucune offre d'emploi à venir",
    "ACTIVE": "ACTIVE",
    "CLOSED": "FERMÉE",
    "editOrder": "Modifier le bon de travail",
    "viewOrder": "Voir le bon de travail",
    "closeOrder": "Marquer comme fermé",
    "Work Order Detail": "Détail de l'ordre de travail",
    "Edit": "Modifier",
    "MarkasClosed": "Marquer comme fermé",
    "CLIENT DETAILS": "COORDONNÉES DU CLIENT",
    "Name": "Nom",
    "Mobile": "Mobile",
    "Email": "E-mail",
    "ClientAddress:": "Adresse client :",
    "UPCOMING JOBS": "EMPLOIS À VENIR",
    "Date": "Date",
    "Time": "Temps",
    "schedulejob": "programmer un travail",
    "Never": "Jamais",
    "Monthly": "Mensuelle",
    "Instructions": "Instructions",
    "JOBS": "EMPLOIS",
    "+ Add Job": "+ Ajouter un emploi",
    "JOB ID": "ID DE TRAVAIL",
    "DATE": "DATE",
    "View Job": "Voir l'emploi",
    "Delete Job": "Supprimer le travail",
    "noWorkOrder": "Il n'y a pas de bons de travail",
    "wishToContinue": "Souhaitez-vous toujours continuer ?",
    "Yes": "Oui",
    "No": "Non",
    "Workorder": "Demande de service",
    "Client Name": "Nom du client",
    "Status": "Statut",
    "Scheduled": "Programmé",
    "Address": "Adresse",
    "Assignees": "Cessionnaires",
    "Details": "Détails",
    "Time Worked": "Temps travaillé",
    "Time Started": "Heure de début",
    "Time Stoped": "Temps arrêté",
    "MarkasRead": "Marquer comme lu",
    "createWO": "Créer un bon de travail",
    "jobsiteInfo": "Informations sur le chantier",
    "budgetDetails": "Détail du budget",
    "ON ARRIVAL": "Détail du budget",
    "ON DEPARTURE": "AU DEPART",
    "includeNonBillable": "Inclure le temps non facturable",
    "addWO": "Ajouter un nouveau bon de travail",
    "Radius": "Rayon",
    "meters": "mètres",
    "Next": "Suivante",
    "scheduleWork": "Planifier le travail",
    "readyToSchedule": "Êtes-vous prêt à le programmer et à l'attribuer?",
    "NotNow": "Pas maintenant",
    "Schedule": "Programmez-le"
  },
  "timeoff":{
    "timeoffTitle": "Temps libre",
    "timeoffPolicyName": "NOM DE LA POLITIQUE",
    "timeoffStart": "Commencer",
    "timeoffEnd": "Fin",
    "timeoffHours": "Heures",
    "timeoffRequestedOn": "Demandé le",
    "timeoffUserReason": "Raison de l'utilisateur",
    "timeoffStatus": "Statut",
    "timeoffViewReaquest":"Voir la demande",
    "timeoffedit":"Modifier la demande",
    "timeoffDenyRequest":"Refuser la demande",
    "timeoffApproveRequest":"Approuver la demande",
    "timeoffDeleteRequest":"Supprimer la demande",
    "timeoffescalate":"Escalader la demande",
    "timeoffUpload": "Télécharger",
    "timeoffSettings": "Paramètres",
    "timeoffSubmitted": "Soumise",
    "timeoffApproved" : "Approuvée",
    "timeoffDenied": "Refusée",
    "timeoffRequestButton": "Congé demandé",
    "timeoffNoData": "Il n'y a pas de demandes de congé faites!",
    "timeoffRequestText": "Vos employés peuvent demander des congés; vous pouvez consulter, accepter ou rejeter la demande de congé depuis notre plateforme unifiée.",
    "timeOffBasicText": "Informations de base",
    "timeoffCurrentBalance": "Solde actuel",
    "timeoffTotalTime": "Temps total demandé",
    "timeoffRemainingBalance": "Solde restant",
    "timeoffMember": "Membre",
    "timeoffPolicy": "Politique*",
    "timeoffSpan": "Période*",
    "timeoffAllDay": "Toute la journée",
    "timeoffRequestedTime": "Temps demandé par jour*",
    "timeoffReason": "Raison*",
    "timeoffSave": "Sauvegarder",
    "timeoffBackButton": "Dos",
    "requestTimeoff": "Demander un congé",
    "chooseFile": "Choisir un fichier",
    "noFile": "Aucun fichier sélectionné",
    "fileFormat": "Formats de fichier acceptés : .csv, .xls, .xlsx",
    "downloadSpreadsheet": "Télécharger le modèle de feuille de calcul",
    "Import": "Importer",
    "Cancel": "Annuler",
    "submitted": "soumis",
    "provideReason": "Veuillez fournir une raison !",
    "Submit": "Soumettre",
    "text1": "La politique de temps sélectionnée n'autorise pas un solde négatif",
    "text2": "La politique sélectionnée n'autorise pas des soldes négatifs inférieurs à 100",
    "hoursValidation": "Veuillez entrer un nombre d'heures demandées inférieur à",
    "hours": "heures",
    "Approved": "Approuvé",
    "noResult": "Aucun résultat trouvé",
    "Upgrade": "Mise à niveau",
    "File": "Fichier",
    "Denied": "Refusé"
  },
  "activityModule": {
    "screenshotsSection": {
      "screenshotActivity": "Activité de capture d'écran",
      "filters": "Filtres",
      "export": "Exporter",
      "dailtTotal": "Total quotidien:",
      "viewTimesheet": "Afficher la feuille de temps",
      "viewApp": "Afficher les applications",
      "viewUrl": "Afficher l'URL",
      "totalTimeWorked": "TEMPS TOTAL TRAVAILLÉ:",
      "noActivity": "Aucune activité",
      "today": "Aujourd'hui",
      "noScreenshots": "Les captures d'écran ne sont pas disponibles !",
      "deleteSelectedScreenshot": "Voulez-vous vraiment supprimer la capture d'écran sélectionnée ?",
      "deleteAllSelectedScreenshots": "Voulez-vous vraiment supprimer toutes les captures d'écran sélectionnées ?",
      "filterLabelProject": "Projet",
      "filterLabelTime": "Temps",
      "filterLabelActivityLevel": "Niveau d'activité",
      "apply": "Appliquer",
      "clear": "Clair",
      "manualActivity": "Activité manuelle",
      "ViewImage": "Voir l'image",
      "viewAttendance": "Voir la présence"
    },
    "appSection": {
      "apps": "applications",
      "noApps": "Vous n'utilisez aucune application actuellement !",
      "appWebsite": "APPLICATION/SITE WEB",
      "project": "PROJET",
      "website": "SITE WEB",
      "timeSpent": "TEMPS PASSÉ",
      "session": "SESSION",
      "category": "CATÉGORIE",
      "viewScreenshot": "Afficher la capture d'écran"
    },
    "urlSection": {
      "url": "URL",
      "noUrls": "Vous n'utilisez aucune URL actuellement !"
    }
  },
  "jobsite": {
    "Job Sites": "Sites d'emploi",
    "addjobsite": "Ajouter un chantier",
    "siteMembers": "Membres sur place maintenant",
    "totalVisits": "Nombre total de visites aujourd'hui",
    "deleteJobsiteCheck": "Voulez-vous vraiment supprimer le chantier ?",
    "ON ARRIVAL": "À L'ARRIVÉE",
    "Do not start timer": "Ne démarre pas le minuteur",
    "ON DEPARTURE": "AU DÉPART",
    "Do not stop timer": "N'arrête pas le chronomètre",
    "Add New User": "Ajouter un nouvel utilisateur",
    "Track Time to project": "Suivre le temps nécessaire au projet",
    "Automatic Timer Actions": "Actions automatiques de la minuterie",
    "Radius": "Rayon",
    "Configure Settings": "Configurer les paramètres",
    "Add Job Location": "Ajouter un emplacement de travail",
    "jobsiteText": "Définissez des barrières géographiques autour des chantiers dédiés et commencez à suivre à mesure que les employés entrent ou sortent des chantiers. Suivez également le temps total passé par chaque employé sur un chantier particulier.",
    "addJobsite": "Ajouter un nouveau chantier",
    "EditJobSite": "Modifier le site d'emploi",
    "searchLocation": "Rechercher un emplacement pour ajouter un chantier",
    "searchJobSites": "Rechercher des sites d'emploi",
    "sortBy": "Trier par",
    "cancel": "Annuler",
    "continue": "Continuer",
    "Loading": "Chargement",
    "membOnSite": "Membres sur le site actuellement",
    "totalVisit": "Visites totales aujourd'hui",
    "report": "Voir les rapports du chantier",
    "Address": "Adresse*",
    "Cancel": "Annuler",
    "Continue": "Continuer",
    "Save": "Enregistrer",
    "Group": "Groupe",
    "searchLocationPlaceholder": "Rechercher un emplacement"
  },
  "profile": {
    "profile": "Profil",
    "editProfile": "Modifier votre profil",
    "fullName": "Nom et prénom",
    "countryCode": "Code postal",
    "phoneNumber": "Pas de téléphone.",
    "email": "E-mail",
    "Password":"Mot de passe",
    "timezone": "Fuseau horaire",
    "emailAlert": "Vérification de l'e-mail en attente pour",
    "timezoneText": "Si vous ne trouvez pas le fuseau horaire exact dans votre système, veuillez sélectionner la ville la plus proche avec le même code GMT.",
    "weeklyOffset": "Repos hebdomadaire",
    "cancel": "Annuler",
    "save": "Sauvegarder",
    "deactivate": "Désactiver le compte",
    "delete": "Supprimer le compte",
    "changeEmail": "CHANGER L'E-MAIL",
    "currentemail": "Entrer le mot de passe actuel *",
    "enterNew": "Entrer un nouveau courriel",
    "contfirmMail": "Confirmer le nouveau Email",
    "detect" : "Détecter",
    "changePassword": "Changer le mot de passe",
    "newPassword": "Nouveau mot de passe *",
    "confirmPassword": "Confirmez le mot de passe *",
    "currentPassword": "Mot de passe actuel *",
    "changePicture" : "CHANGER L'IMAGE",
    "addPicture" : "Ajouter une image",
    "addPictureText": "Veuillez vous assurer que lors du téléchargement de votre (vos) photo(s) de profil, votre visage est clairement visible. C'est important car vos photos de profil seront utilisées dans la reconnaissance faciale si vous démarrez l'heure sur votre téléphone portable.",
    "bottomImg": "Coller et imager, faire glisser et déposer des fichiers ou",
    "clickHere": "Cliquez ici",
    "toUpload": "télécharger",
    "deactivateTxt": "Désactiver",
    "IdLabel": "Saisissez le nom de l'ID",
    "changePwd": "Changer le mot de passe",
    "deleteText": "Supprimer le compte - Après la suppression de votre compte, vous ne pourrez plus vous connecter et vous ne pourrez pas non plus exécuter votre application de bureau.",
    "accountErr": "Le numéro de compte n'est pas valide. Il devrait comporter 16 chiffres.",
    "accountValidation": "Il doit comporter 11 caractères alphanumériques.",
    "uploadTooltip": "Les types de fichiers pris en charge sont .pdf, .jpeg, .png, .jpg, .tiff, .eps. La taille maximale du fichier prise en charge est de 1 Mo.",
    "enableSetting": "Veuillez activer l'option d'édition",
    "viewPhoto": "Afficher toutes les photos",
    "selectTodo": "Sélectionner une tâche à faire",
    "invalidFileType": "Type de fichier invalide"
  },
  "people": {
    "bulInvite": {
      "noData": "Aucune donnée disponible",
      "dateTime": "DATE ET HEURE",
      "sheetName": "NOM DE LA FEUILLE",
      "INVITESUMMARY": "RÉSUMÉ DE L'INVITATION",
      "viewDetails": "Voir les détails",
      "Member Details": "Détails du membre",
      "Status": "Statut",
      "Reason": "Raison",
      "SUCCESS": "SUCCÈS",
      "FAILED": "ÉCHOUÉ"
    }
  },
  "schedule": {
    "Schedules": "Des horaires",
    "Add Schedule": "Ajouter un horaire",
    "MEMBERS": "MEMBRES",
    "addScheduleText": "Désormais, vous pouvez facilement créer, attribuer et suivre les quarts de travail des employés. Corrigez les écarts de quart de travail, comme les employés qui arrivent en retard pour les quarts de travail ou qui abandonnent les quarts de travail.",
    "basicInfo": "Informations de base",
    "editSchedule": "Modifier l'horaire",
    "editScheduleText": "Now you can easily create, assign & track employee shifts. Fix the shift discrepancies like employees arriving late for shifts or abandoning shifts.",
    "deleteAll": "Oui, tout supprimer !",
    "deleteOne": "Non, supprimer seulement un !",
    "areYouSure": "Êtes-vous sûr(e) ?",
    "deleteSchedule": "Voulez-vous supprimer tous les horaires ?",
    "Upgrade": "Mettre à niveau",
    "noSchedule": "Aucun horaire de travail pour cette date !",
    "Weekly": "Hebdomadaire",
    "Today": "Aujourd'hui",
    "noResult": "Aucun résultat trouvé",
    "allMembers": "Tous les membres",
    "allTeams": "Toutes les équipes",
    "Teams": "Équipes"
  },
  "client": {
    "editClient": {
      "editclient": "Modifier le client",
      "editText": "Modifiez les clients et invitez-les à consulter et à suivre les projets/tâches pour apporter plus de transparence à votre processus.",
      "GI": "informations générales",
      "CI": "Coordonnées",
      "PD": "Détails du projet",
      "BD": "Détails budgétaires",
      "Cancel": "Annuler",
      "Continue": "Continuer",
      "billableTime": "Inclure le temps non facturable",
      "Save": "Sauvegarder",
      "startDate": "Date de début",
      "address": "Adresse du client",
      "email": "E-mail du client",
      "code": "Code postal",
      "phone": "Pas de téléphone.",
      "Project": "Projet",
      "Type": "Taper",
      "Based": "Basé sur",
      "cost": "Coût",
      "Hours": "Heures",
      "Notify": "Avertir à",
      "Resets": "Réinitialise",
      "budgetMode": "Mode budgétaire",
      "budgetType": "Type de budget",
      "projectPerHr" : "Tarif horaire du projet"
    },
    "addClient": {
      "addNewClient": "Ajouter un nouveau client",
      "addClientText": "Ajoutez des clients et invitez-les à consulter et à suivre les projets/tâches pour apporter plus de transparence à votre processus.",
      "editClient": "Modifier le client"
    },
    "activeClient": {
      "activeclient": "CLIENTS ACTIFS",
      "clientDetail": "COORDONNÉES DU CLIENT",
      "PHONE": "TÉLÉPHONE",
      "budget": "BUDGET DU CLIENT",
      "PROJECTS": "PROJETS",
      "Total": "Totale",
      "Archive client": "Client d'archivage",
      "InvitationStatus" : "Statut de l'invitation"
    },
    "archiveClients": {
      "ARCHIVEDCLIENTS": "CLIENTS ARCHIVÉS",
      "NAME": "NOM",
      "EditClient": "Modifier le client",
      "restoreClient": "Restaurer le client"
    }
  },
  "timesheetModule": {
    "viewTimesheetSection": {
      "timeType": "Type de Temps",
      "breakType": "Type de pause",
      "viewTimesheet": "Afficher la feuille de temps",
      "noData": "Pas de données disponibles!",
      "addTime": "Ajouter du temps",
      "addTimeLog": "Ajouter un journal de temps",
      "addWeeklyTimeLog": "Ajouter un journal de temps hebdomadaire",
      "today": "Aujourd'hui",
      "weekly": "Hebdomadaire",
      "monthly": "Mensuel",
      "dateRange": "Plage de dates",
      "note": "Remarque : L'heure indiquée correspond au fuseau horaire de l'utilisateur sélectionné.",
      "filters": "Filtres",
      "exports": "Exportations",
      "switchView": "Changer de vue",
      "projectDetails": "détails du projet",
      "activityTime": "Temps d'activité",
      "idleTime": "Temps d'inactivité",
      "manualTime": "Heure manuelle",
      "breakTime": "Pause",
      "effectiveTime": "Temps effectif",
      "duration": "Durée",
      "nodataDate": "Aucune donnée pour cette date !",
      "time": "Temps",
      "allTimetype": "Type de tous les temps",
      "normal": "Normal",
      "idle": "Inactif",
      "manual": "Manuel",
      "activityLevel": "Niveau d'activité",
      "allActivityLevel": "Tout niveau d'activité",
      "apply": "Appliquer",
      "clear": "Clair",
      "viewTimesheetOption": "Afficher la feuille de temps",
      "deleteTimesheetOption": "Supprimer la feuille de temps",
      "viewScreenshot": "Afficher la capture d'écran",
      "viewApps": "Afficher les applications",
      "viewUrl": "Afficher l'URL",
      "weeklyTimeline": "CHRONOLOGIE HEBDOMADAIRE",
      "date": "Date",
      "timeTracked": "Temps suivi",
      "startTime": "Heure de début",
      "endTime": "Heure de fin",
      "effectiveTime": "TEMPS EFFECTIF",
      "activityPer": "ACTIVITÉ %",
      "activity": "Activité",
      "timeline": "CALENDRIER",
      "addTimesheet": "Ajouter une feuille de temps",
      "addTimeDesc": "Vous pouvez saisir une feuille de temps manuelle à partir d'ici pour n'importe quelle date et heure spécifiques.",
      "genInfo": "Informations générales",
      "memNameLabel": "Nom de membre",
      "projNameLabel": "nom du projet",
      "todoNameLabel": "Nom de la tâche",
      "billable": "Facturable",
      "reason": "Raison",
      "noteSwitch": "Note",
      "save": "Sauvegarder",
      "advanceFilter": "Filtre avancé",
      "downloadTimesheet": "Télécharger les feuilles de temps",
      "PDF": "PDF",
      "CSV": "CSV",
      "PRODUCTIVITY": "PRODUCTIVITÉ",
      "TIME": "TEMPS",
      "workingHours": "Heures de travail",
      "totalHours": "Total des heures",
      "editTimesheet": "Modifier la feuille de temps",
      "deleteTimesheet": "Supprimer la feuille de temps",
      "screenTime": "Temps d'écran",
      "mobileTime": "Temps mobile",
      "canDeletetimesheet": "Supprimer cette feuille de temps?",
      "text1": "La suppression de la feuille de temps supprimera toutes les données d'activité, y compris les captures d'écran, les applications et les URL",
      "Automatic": "Automatique",
      "Manual": "Manuel",
      "Both": "Les deux",
      "whyareyouadding": "Pourquoi ajoutes-tu ?",
      "workingOn": "Sur quoi travaillais-tu ?"
    },
    "approveTimesheetSection": {
      "timesheetApproval": "Approbations des feuilles de temps",
      "manualTimesheet": "Feuille de temps manuelle",
      "automaticTimesheet": "Feuille de temps automatique",
      "timesheetType": "Type de feuille de temps",
      "memberName": "NOM DE MEMBRE",
      "date": "DATE",
      "startTime": "HEURE DE DÉBUT",
      "endTime": "HEURE DE FIN",
      "duration": "DURÉE",
      "activity": "ACTIVITÉ",
      "status": "STATUT",
      "reason": "RAISON",
      "viewreq": "Afficher la demande",
      "approve": "Approuver",
      "deny": "Refuser",
      "provideReason": "Veuillez fournir la raison !",
      "typeReason": "Tapez votre raison",
      "submit": "Soumettre",
      "viewTimesheet": "Afficher la feuille de temps",
      "viewTimeDesc": "Vous pouvez afficher les détails de la feuille de temps à partir d'ici ainsi que la raison et les notes soumises (le cas échéant).",
      "genInfo": "Informations générales",
      "memName": "Nom de membre",
      "datey": "Date",
      "startTimey": "Heure de début",
      "endTimey": "Heure de fin",
      "durationy": "Durée",
      "activityy": "Activité",
      "statusy": "Statut",
      "approvedBy": "Approuvé par",
      "doApproval": "Date d'approbation",
      "toApproval": "Moment d'approbation",
      "reasonForTimesheet": "Raison de la feuille de temps",
      "reasonForApproval": "Motif de l'approbation",
      "Back": "Retour",
      "deniedBy": "Refusé par",
      "deniedDate": "Date du refus",
      "deniedTime": "Heure du refus",
      "denialReason": "Raison du refus",
      "Notes": "Notes",
      "projectName": "Nom du projet",
      "todoName": "Nom de la tâche",
      "Idle": "Inactif",
      "Manual": "Manuel",
      "timeSpan": "Intervalle de temps (IST)",
      "Reason": "Raison",
      "editTimesheet": "Modifier la feuille de temps",
      "timeValidation": "Veuillez saisir l'heure au format AM/PM",
      "startEndTimeValidation": "L'heure de fin doit être supérieure à l'heure de début",
      "textone": "Vous pouvez saisir une feuille de temps manuelle d'ici pour une date et une heure spécifiques.",
      "timeFormat": "Veuillez saisir l'heure au format AM/PM",
      "Billable": "Facturable",
      "type": "Type",
      "dataUnavailable": "Les données ne sont pas disponibles !",
      "timesheetUpdated": "La feuille de temps a été mise à jour avec succès."
    },
    "attendanceSection": {
      "attendance": "Présence",
      "attendanceEntry": "Entrée de présence",
      "shiftEntry": "Entrée de quart",
      "last30days": "Les 30 derniers jours",
      "checkIn": "ENREGISTREMENT",
      "checkOut": "VÉRIFIER",
      "attendanceVisual": "VISUEL DE PRÉSENCE",
      "totalHrs": "TOTAL HEURES",
      "effectiveHrs": "HRS EFFICACE",
      "arrivals": "ARRIVÉES",
      "log": "ENREGISTRER",
      "firstPunchIn": "Premier(s) Punch In(s) & Dernier(s) Punch Out(s)",
      "allPunchIn": "Tous les Punch In(s) & Punch Out(s)",
      "advanceFilter": "Filtres avancés",
      "Download": "Télécharger",
      "exportPDF": "Exporter en PDF",
      "exportCSV": "Exporter en CSV",
      "weeklyOff": "Jour de repos hebdomadaire complet",
      "Missed": "Manqué",
      "Abandoned": "Abandonné",
      "Incompleted": "Incomplet",
      "dataNotAvailable": "Les données pour cette plage de dates ne sont pas disponibles !",
      "Delayed": "En retard",
      "onTime": "À l'heure",
      "All": "Tous",
      "pendingShift": "Shift en attente",
      "completedShift": "Shift terminé",
      "mapView": "Vue carte",
      "keyboard": "Clavier",
      "mouse": "Souris",
      "attendanceStats": "Statistiques de présence",
      "totaldays": "Jours totaux",
      "workingDays": "Jours travaillés",
      "daysPresent": "Jours présents",
      "daysAbsent": "Jours d'absence",
      "workingHrs": "Heures de travail moyennes",
      "breakHrs": "Heures de pause moyennes",
      "attendancePercentage": "Taux de présence",
      "totalLeaves": "Total des congés",
      "paid": "Payé",
      "unpaid": "Non payé"
    },
    "WeeklyTimeLog": {      
      "weeklyTimesheet": "Feuille de temps hebdomadaire",
      "Weekly": "Hebdomadaire",
      "addRow": "AJOUTER UNE LIGNE",
      "USER": "UTILISATEUR",
      "PROJECT": "PROJET",
      "TOTAL": "TOTAL",
      "markAsBillable": "Marquer comme facturable",
      "addTime": "Ajouter du temps",
      "timeSpan": "Intervalle de temps (IST)"
    }
  },
  "members": {
    "invitationTomember":"envoyer une invitation au membre",
    "bulkInvite": "Invitation groupée",
    "inviteNewMem": "Inviter de nouveaux membres",
    "total": "TOTAL",
    "working": "EN TRAVAIL",
    "online": "EN LIGNE",
    "offline": "HORS LIGNE",
    "deleted": "SUPPRIMÉ",
    "deactivated": "DÉSACTIVÉ",
    "userType": "Type d'utilisateur",
    "selectUserType": "sélectionner le type d'utilisateur",
    "member": "MEMBRE",
    "role": "RÔLE",
    "projects": "PROJETS",
    "status": "STATUT",
    "payment": "PAIEMENT",
    "priority": "LIMITE JOURNALIÈRE/LIMITE HEBDOMADAIRE",
    "editLimit": "Modifier la limite",
    "disableTracking": "Désactiver le suivi",
    "removeMember": "Supprimer un membre",
    "enableTracking": "Activer le suivi",
    "searchMember": "Rechercher un membre",
    "importBulk": "Importer des membres en masse",
    "importBulkDesc": "Vous pouvez télécharger le fichier xls avec les détails du membre ci-dessous afin de démarrer le processus d'invitation groupée.",
    "clickHere": "Cliquez ici",
    "to": "pour",
    "downloadSample": "téléchargez un exemple de fichier et remplissez les détails des membres.",
    "importFiles": "Fichier d'importation rempli avec les détails des membres",
    "toUpload": "télécharger",
    "proceed": "Procéder",
    "inviteANewMem": "Inviter un nouveau membre",
    "inviteANewMemDesc": "Invitez facilement vos employés et commencez à suivre leurs progrès ; c'est aussi simple que ça !",
    "basicInfo": "Informations de base",
    "memEmailId": "Identifiant e-mail du membre*",
    "selectRole": "Sélectionnez un rôle pour ce membre*",
    "visibleMode": "Mode visible",
    "stealthMode": "Mode furtif",
    "selectProj": "Sélectionnez des projets pour ce membre",
    "memEmail": "Courriel du membre",
    "editInvite": "Modifier l'invitation",
    "cancelInvite": "Annuler l'invitation",
    "resendinvite": "Renvoyer l'invitation",
    "editLimitDesc": "Vous pouvez définir la limite de temps quotidienne et hebdomadaire du membre individuel à partir d'ici, ainsi que le taux de rémunération horaire.",
    "memberEditlimit": "Membre*",
    "weeklyLimit": "Limite hebdomadaire",
    "dailyLimit": "Limite journalière",
    "hourlyPayRate": "Taux de rémunération horaire",
    "useShift": "Utiliser le décalage pour définir des limites quotidiennes",
    "selectDays": "Sélectionnez les jours",
    "editMemberInvite": "Modifier l'invitation du membre",
    "editMemberInviteDesc": "Invitez facilement vos employés et commencez à suivre leurs progrès ; c'est aussi simple que ça !",
    "members": "MEMBRES",
    "membersTab": "Membres",
    "invitedTab": "Invité",
    "requestedTab": "Demandé",
    "bulkInviteStatusTab": "Statut des invitations groupées",
    "platform": "Plate-forme",
    "noMemReq": "Il n'y a aucun membre demandé !",
    "nomembInvited": "Il n'y a aucun membre invité !",
    "selectProjectInvite": "Sélectionnez des projets pour ce client",
    "clientFlag": "Inviter le client à accéder à l'application",
    "inviteClient": "Identifiant e-mail du client*",
    "Member": "Membre",
    "inviteCancel": "Êtes-vous sûr de vouloir annuler l'invitation partagée ?",
    "statusField":"État"
  },
  "subscription": {
    "overviewplan": "Plan d'ensemble",
    "billingHistory": "Historique de facturation",
    "planOverview":{
      "currentPlan": "PLAN ACTUEL",
      "expdate": "DATE D'EXPIRATION",
      "basePrice": "PRIX DE BASE",
      "changePlan": "CHANGER DE PLAN",
      "planFeature": "CARACTÉRISTIQUES DU PLAN"
    },
    "BillingRate": {
      "paymentHistory": "VOTRE HISTORIQUE DE PAIEMENT",
      "exportPDF": "Exporter au format PDF",
      "exportCSV": "Exporter au format CSV",
      "INVOICE": "FACTURE",
      "PLAN": "PLAN",
      "AMOUNT": "MONTANTE",
      "DATE": "DATE",
      "CARD NUMBER": "NUMÉRO DE CARTE",
      "DESCRIPTION": "DESCRIPTION",
      "STATUS": "STATUT",
      "Email": "E-mail",
      "Download": "Télécharger",
      "noHistory": "Aucun historique de facturation",
      "invoice": "Facture par e-mail",
      "Subject": "Sujet",
      "Message": "Message",
      "Save": "Enregistrer"

    },
    "remaininingText": "Les factures sont jointes. Veuillez télécharger la pièce jointe. Merci."
  },
  "integration": {
    "listing": {
      "Integration": "L'intégration",
      "Add Integration": "Ajouter une intégration",
      "NAME": "NOM",
      "LAST SYNC": "DERNIÈRE SYNCHRONISATION",
      "STATUS": "STATUT",
      "Disabled": "Désactivé",
      "Enabled": "Activé",
      "Remove": "Retirer",
      "chooseIntegration": "Choisissez une intégration",
      "Integrations": "Intégrations",
      "integrationText1": "Connectez-vous à vos applications préférées : une intégration transparente simplifiée",
      "integrationText2": "Workstatus fournit une intégration en un clic avec vos applications préférées. Gagnez du temps et rationalisez vos opérations avec les intégrations Workstatus.",
      "projectmanagement": "Gestion de projet",
      "Zoho": "Zoho",
      "Trello": "Trello",
      "Clickup": "Clic",
      "Teamwork": "Travail en équipe",
      "Private Cloud": "Nuage privé",
      "AWS": "AWS",
      "Mail Server": "Serveur de courrier",
      "SMTP": "SMTP"
    },
    "aws": {
      "AWS Integration": "Intégration AWS",
      "awsText1": "L'intégration AWS permet d'obtenir vos données sur le compartiment AWS S3. Vous pouvez intégrer votre compartiment pour stocker les captures d'écran, les images de profil, les pièces jointes de tâches et les rapports sur votre stockage S3. Cela garantit la confidentialité des données.",
      "label": {
        "accesskey": "ID de clé d'accès AWS",
        "secretkey": "CLÉ D'ACCÈS SECRÈTE AWS",
        "region": "RÉGION AWS",
        "bucket": "NOM DU BUCKET AWS"
      },
      "setupText1": "Comment configurer AWS ?",
      "setupText2": "ID de clé d'accès AWS :",
      "setupText3": "Votre clé AWS",
      "secretKeyAccess": "Clé d'accès secrète AWS :",
      "inputSecretKey": "Veuillez entrer votre clé secrète.",
      "AWS Region:": "Région AWS :",
      "yourRegion": "Votre région AWS",
      "bucketName": "Nom du compartiment AWS :",
      "yourBucketname": "Votre nom de compartiment AWS",
      "setupText4": "Le test de connexion consiste à vérifier l'état de synchronisation de votre compte AWS avec le workstatus. Si les détails sont tous corrects et que votre connexion est établie avec succès, elle apparaîtra comme Activée dans la liste d'intégration."
    },
    "smtp": {
      "SMTP Integration": "Intégration SMTP",
      "smtpText1": "L'intégration SMTP vous aidera à configurer votre propre serveur de messagerie avec Workstatus. Envoyez toutes les notifications par e-mail via votre serveur de messagerie à tous les utilisateurs de votre organisation.",
      "label": {
        "MailServer": "Serveur de courrier",
        "MailDriver": "Pilote de messagerie",
        "SMTPMethod": "Méthode sécurisée SMTP",
        "Username": "Username",
        "Port": "Port",
        "Name": "Nom",
        "Email": "E-mail",
        "Password": "Mot de passe"
      },
      "smtpText2": "Comment configurer SMTP pour différents MAIL SERVER ?",
      "Gmail": "Gmail",
      "Hotmail": "Hotmail",
      "Yahoo": "Yahoo",
      "OtherServer": "Autre serveur",
      "Mailserver:": "Serveur de courrier:",
      "Yourmailserver": "Votre serveur de messagerie SMTP",
      "TLS/SSL": "TLS/SSL",
      "smtpText3": "587 (TLS)/465 (SSL), cela peut être différent pour cela, veuillez vous adresser à l'équipe de votre fournisseur de serveur.",
      "Username/ Email:": "Nom d'utilisateur/ e-mail :",
      "smtpText4": "Veuillez saisir votre nom d'utilisateur/e-mail de serveur.",
      "Yourpassword": "Your password",
      "equires authentication:": "nécessite une authentification :",
      "Yes": "Oui",
      "Note:": "Note:",
      "smtpText5": "Afin de vérifier votre connexion SMTP, saisissez vos informations SMTP et cliquez sur le bouton TESTER LA CONNEXION. Une fois la connexion établie, enregistrez les paramètres et envoyez votre facture à l'aide de votre serveur de messagerie.",
      "smtpText6": "Si vous rencontrez toujours l'erreur, vérifiez votre paramètre et réessayez.",
      "smtpText7": "Pour d'autres questions, n'hésitez pas à nous contacter par e-mail Support ou en utilisant le chat en direct (10h00 à 22h00 IST).",
      "smtpText8": "Le test de connexion consiste à vérifier l'état de synchronisation de votre compte SMTP avec le workstatus. Si les détails sont tous corrects et que votre connexion est établie avec succès, elle apparaîtra comme Activée dans la liste d'intégration.",
      "smtpText9": "Tester le texte associé à la connexion",
      "Submit": "Soumettre"
    },
    "commonVariable": {
      "Save": "Sauvegarder",
      "testconnection": "Tester la connexion",
      "Reset": "Réinitialiser"
    },
    "reauthenticate": "Ré-authentifier.",
    "integrationKey": "Clé AWS pour l'intégration",
    "secretKey": "Clé secrète pour l'intégration",
    "awsKey": "Région AWS pour l'intégration",
    "bucketKey": "Nom du bucket pour l'intégration",
    "alertMessage": "Êtes-vous sûr de vouloir mettre à jour ? Tous les paramètres seront supprimés.",
    "serverDetails": "Entrez les détails de votre serveur de messagerie",
    "driveDetail": "Entrez les détails de votre lecteur de messagerie",
    "secretSmtp": "Entrez votre méthode SMTP sécurisée",
    "mailPort": "Entrez votre PORT de messagerie",
    "userName": "Entrez votre nom d'utilisateur",
    "name": "Entrez votre nom",
    "mail": "Entrez votre adresse e-mail",
    "mailPassword": "Entrez votre mot de passe de messagerie"
  },
  "dashboardAnalytics": {
    "Total activity": "Activité totale",
    "Total Worked": "Total travaillé",
    "Total Earned": "Total gagné",
    "Project worked": "Projets travaillés",
    "Hours Worked Per Day": "Heures travaillées par jour",
    "Activity graph": "Graphique d'activité",
    "Top Projects": "Meilleurs projets",
    "Online Members": "Membres en ligne",
    "Highest % of Manual & Mobile Time": "Plus haut % de temps manuel et mobile",
    "Tracked Most Hours": "Heures les plus suivies",
    "Tracked Least Hours": "Heures les moins suivies",
    "Recent Used Website": "Site Web récemment utilisé",
    "Recent Used Application": "Application récemment utilisée",
    "Haven't Tracked Time": "N'a pas suivi le temps",
    "Member Location": "Emplacement des membres",
    "hello": "Bonjour",
    "tabMe": "Moi",
    "tabAll": "Tout",
    "totalActivity": "ACTIVITÉ TOTALE SUIVI",
    "totalWorked": "TEMPS TOTAL TRAVAILLÉ",
    "totalEarned": "TOTAL GAGNÉ",
    "totalProject": "TOTAL PROJET TRAVAILLÉ",
    "topProjects": "MEILLEURS PROJETS",
    "members": "MEMBRES",
    "highestManualMobile": "% LE PLUS ÉLEVÉ DE TEMPS MANUEL ET MOBILE",
    "trackedMostHrs": "SUIVI DU PLUPART DES HEURES",
    "trackedLeastHrs": "MOINS D'HEURES SUIVIES",
    "recentUsedApps": "APPLICATIONS RÉCENTES UTILISÉES",
    "recentUsedSites": "SITES WEB UTILISÉS RÉCENTS",
    "haventTrackedTime": "Je n'ai pas encore suivi le temps",
    "shiftSchedule": "HORAIRE DE QUARTS",
    "timeoff": "TEMPS LIBRE",
    "jobsite": "CHANTIER",
    "timeline": "CHRONOLOGIE",
    "activityGraph": "GRAPHIQUE D'ACTIVITÉ",
    "recentScreenshot": "CAPTURES D'ÉCRAN RÉCENTES",
    "recentProjects": "LES PROJETS RÉCENTS",
    "recentTimesheet": "FEUILLES DE TEMPS RÉCENTES",
    "recentTodos": "TÂCHES RÉCENTES",
    "noDataFound": "Aucune donnée disponible.",
    "startTime": "Heure de début",
    "endTime": "Heure de fin",
    "noScreenshots": "Aucune capture d'écran.",
    "noRecentActivity": "Aucune activité récente.",
    "projectNotAssigned": "Les projets ne vous sont pas attribués.",
    "currentAppsUrls": "Actuellement, les données sur les applications et les URL ne sont pas disponibles.",
    "currentTiemsheet": "Actuellement, les données des feuilles de temps ne sont pas disponibles.",
    "todosNotAssigned": "Les tâches ne vous sont pas assignées.",
    "screenTime": "Temps d'écran",
    "manualTime": "Heure manuelle",
    "mobileTime": "Temps mobile",
    "breakTime": "Pause",
    "overexceed": "Au-delà du temps de pause",
    "rejectTime": "Heure rejetée",
    "idleTime": "Temps inactivité",
    "viewAllSs": "Afficher toutes les captures d'écran",
    "viewProj": "Voir les projets",
    "viewUrl": "Afficher les URL",
    "viewApps": "Afficher les applications",
    "viewTimesheet": "Afficher les feuilles de temps",
    "viewTodo": "Afficher les tâches",
    "viewAllMem": "Voir tous les membres",
    "viewMore": "Voir plus"  ,
    "viewReport": "Voir le rapport",
    "date": "Date",
    "timeTracked": "Temps suivi",
    "totalHrsWorkedPerDay": "TOTAL DES HEURES TRAVAILLÉES PAR JOUR",
    "Today": "Aujourd'hui",
    "Weekly": "Hebdomadaire",
    "Monthly": "Mensuel",
    "dateRange": "Plage de dates",
    "viewImage": "Voir l'image",
    "appDataNotAvailable": "Les données de l'application ne sont actuellement pas disponibles !",
    "strtTime": "Heure de début",
    "stpTime": "Heure de fin",
    "todoName": "Nom de la tâche",
    "todoTime": "Temps de tâche",
    "Offline": "Hors ligne",
    "Online": "En ligne",
    "Android": "Android",
    "iOS": "iOS",
    "Windows": "Windows",
    "MacOS": "MacOS",
    "Web": "Web",
    "Linux": "Linux",
    "noTrackhours": "Aucune heure suivie",
    "searchWidget": "Sélectionner des widgets",
    "total": "Total"
  },
  "schedules": {
    "schedules": "Des horaires",
    "addSchedule": "Ajouter un horaire",
    "addScheduleDesc": "Vous pouvez désormais facilement créer, attribuer et suivre les quarts de travail des employés. Corrigez les écarts entre les quarts de travail, comme les employés qui arrivent en retard ou qui abandonnent des quarts de travail.",
    "basicInfo": "Informations de base",
    "member": "Membre*",
    "title": "Titre*",
    "description": "Description",
    "shiftDur": "Durée du quart de travail",
    "minHrs": "Heures minimales*",
    "recShiftOpt": "Options de quarts de travail récurrents*",
    "save": "Sauvegarder",
    "editSchedule": "Modifier le calendrier",
    "repeatUntil": "Repeat Until",
    "selectedDays": "Jours sélectionnés",
    "delete": "Supprimer",
    "back": "Retour",
    "validation": "La durée minimale du quart de travail est de",
    "hours": "heures !"
  },
  "helpDesk": {
   "supportHelp": "Assistance et aide",
   "orgId": "Identifiant de l'organisation :",
   "orgText": "Partagez cet identifiant d'organisation avec notre équipe d'assistance",
   "supportHrs": "Nos horaires d'assistance :",
   "dayTime": "10h00 - 18h00. Lundi vendredi",
   "successManager": "Votre responsable de la réussite client",
   "escalation": "POUR LES ESCALADES, VEUILLEZ CONTACTER",
   "chatWus": "Discute avec nous",
   "visitHelpCenter": "Visitez le centre d'aide",
    "gotIt" : "Compris"
  },
  "department": {
    "department": "Département",
    "departmentName": "Nom du département",
    "departmentHead": "Chef de département",
    "team": "Équipe",
    "members": "Membres",
    "adddepartment": "Nouveau département",
    "edit": "Modifier le département",
    "remove": "Supprimer le département",
    "noDepts": "Il n'y a pas de départements !",
    "confirmremove": "Le département sera supprimé. Souhaitez-vous continuer ?",
    "yes": "Oui",
    "no": "Non",
    "ok": "OK",
    "addNewDept": "Ajouter un nouveau département",
    "createDept": "Créez différents équipes/départements pour rationaliser votre flux de travail.",
    "bsicInf": "Informations de base",
    "back": "Retour",
    "depNameLabel": "Nom du département*",
    "depHeadLabel": "Chef de département*",
    "teamLabel": "Équipe*",
    "searchTeamPlaceholder": "Rechercher une équipe",
    "memLabel": "Membres",
    "save": "Enregistrer",
    "editDept": "Modifier le département",
    "searchDept": "Rechercher un département"
  },
  "reports": {
    "timeAndActivity": "Rapports de temps et d'activité",
    "dataCheck": "Les données dans les rapports sont disponibles jusqu'à hier. Les données d'aujourd'hui ne seront pas disponibles.",
    "timeAndActivityDesc": "Consultez le total des heures travaillées par les membres de l'équipe, le temps actif et le temps d'inactivité.",
    "selectBy": "Sélectionner par",
    "selectDept": "Sélectionner un département",
    "selectMember": "Sélectionner un membre",
    "selectTeam": "Sélectionner une équipe",
    "daterange": "Plage de dates",
    "groupBy": "Regrouper par",
    "project": "Projet",
    "todo": "Tâche à faire",
    "hours": "Heures",
    "activityLevel": "Niveau d'activité",
    "generateReport": "Générer un rapport",
    "note": "Note : L'heure affichée est conforme au fuseau horaire de l'utilisateur sélectionné.",
    "filters": "Filtres",
    "send": "Envoyer",
    "schedule": "Planifier",
    "download": "Télécharger",
    "scheduleRep": "Planifier le rapport",
    "sendRep": "Envoyer le rapport",
    "apply": "Appliquer",
    "clear": "Effacer",
    "effectiveTime": "TEMPS EFFECTIF",
    "totalHrsWorkedPerDay": "TOTAL DES HEURES TRAVAILLÉES PAR JOUR",
    "avgActivity": "ACTIVITÉ MOYENNE",
    "member": "MEMBRE",
    "date": "DATE",
    "projDetails": "DÉTAILS DU PROJET",
    "activity": "ACTIVITÉ",
    "idle": "INACTIVITÉ",
    "manual": "MANUEL",
    "break": "PAUSE",
    "effective": "EFFICACE",
    "time": "TEMPS",
    "totalHrs": "TOTAL DES HEURES",
    "searchMem": "Rechercher un membre",
    "searchTeam": "Rechercher une équipe",
    "searchDepartment": "Rechercher un département",
    "searchProject": "Rechercher un projet",
    "searchTodo": "Rechercher une tâche à faire",
    "departmentTab": "Départements",
    "teamsTab": "Équipes",
    "memberTab": "Membres",
    "weeklyReports": "Rapports hebdomadaires",
    "weeklyReportsdesc": "Heures moyennes, activité moyenne par jour et montant total gagné.",
    "dataNotAvailabel": "Les données pour cette plage de dates ne sont pas disponibles !",
    "memWorked": "MEMBRES TRAVAILLÉS",
    "avgHrsPerDay": "MOYENNE D'HEURES PAR JOUR",
    "earned": "GAGNÉ",
    "memberName": "NOM DU MEMBRE",
    "totalHours": "TOTAL DES HEURES",
    "avgPercentage": "MOYENNE EN POURCENTAGE",
    "dailyTotal": "Total quotidien",
    "customrepo": "Rapport personnalisé",
  "basicDet": "Détails de base",
  "selectAll": "Sélectionner tout",
  "unSelectAll": "Tout désélectionner",
  "name": "Nom",
  "email": "Email",
  "teamName": "Nom de l'équipe",
  "teamLeadName": "Nom du chef d'équipe",
  "activityDet": "Détails de l'activité",
  "totalHrss": "Total des heures",
  "totalidle": "Temps total d'inactivité",
  "idleTimeP": "Pourcentage de temps d'inactivité",
  "totalSearchDays": "Nombre total de jours de recherche",
  "attendacedet": "Détails de la présence",
  "weekoffdays": "Jours de congé hebdomadaires",
  "shiftHrs": "Heures de travail",
  "totalWorkDays": "Nombre total de jours travaillés",
  "totalEffectiveHrs": "Nombre total d'heures effectives",
  "totalBreakTime": "Temps total de pause",
  "breakTimeP": "Pourcentage de temps de pause",
  "totalAtt": "Présence totale",
  "att": "Pourcentage de présence",
  "totalTiemoffReq": "Nombre total de demandes de congé",
  "totalTimeofHrsReq": "Nombre total d'heures de congé demandées",
  "projectDetails": "Détails du projet",
  "NoOfProjWork": "Nombre de projets travaillés",
  "noOfTodoWork": "Nombre de tâches à faire travaillées",
  "noOfCompTodo": "Nombre de tâches à faire terminées",
  "jobsiteDets": "Détails du chantier",
  "jobsiteVisited": "Nombre de chantiers visités",
  "totalTimeOnAllJs": "Temps total sur tous les chantiers",
  "noOfDaysOnAllJS": "Nombre de jours sur tous les chantiers",
  "totalTimeOnJs": "Temps total sur les chantiers (%)",
  "exportCsv": "EXPORTER EN CSV",
  "appsNUrl": "Rapports sur les applications et les URL",
  "appUrlDesc": "Afficher les applications utilisées et les URL visitées par les membres de l'équipe.",
  "category": "Catégorie",
  "session": "Session",
  "manualTimeEditRep": "Rapports de modification manuelle du temps",
  "manualTimeEditDesc": "Afficher le temps ajouté manuellement par les membres de l'équipe, y compris la période de temps.",
  "action": "Action",
  "changedBy": "Modifié par",
  "affectedMem": "MEMBRES CONCERNÉS",
  "hrsAdded": "HEURES AJOUTÉES",
  "hrsDeleted": "HEURES SUPPRIMÉES",
  "timeSpan": "PÉRIODE DE TEMPS",
  "timeChange": "MODIFICATION DU TEMPS",
  "status": "Statut",
  "timesheetApprReport": "Rapport d'approbation des feuilles de temps",
  "timesheetApprDesc": "Afficher les feuilles de temps des membres de l'équipe et le statut de leurs demandes.",
  "projWorkOrder": "PROJET/ORDRE DE TRAVAIL",
  "startTime": "HEURE DE DÉBUT",
  "endTime": "HEURE DE FIN",
  "timesheetStatus": "STATUT DE LA FEUILLE DE TEMPS",
  "apprDeniAt": "APPROUVÉ/REFUSÉ À",
  "apprDeniBy": "APPROUVÉ/REFUSÉ PAR",
  "reason": "Raison",
  "projTodoRep": "Rapport de projet/Todo",
  "projTodoDesc": "Afficher les membres de l'équipe travaillant sur des projets, les tâches assignées, l'état, la date de début et de fin des projets.",
  "projName": "NOM DU PROJET",
  "estHrs": "HEURES ESTIMÉES",
  "timeSpent": "TEMPS PASSÉ",
  "attBasic": "Rapport de présence de base",
  "attBasicDesc": "Analysez les heures de travail moyennes de votre équipe, les heures de pause et le pourcentage de présence.",
  "avgWorkHrs": "MOY. HEURES DE TRAVAIL",
  "avgAtt": "MOY. PRÉSENCE",
  "avgBreakHrs": "MOY. HEURES DE PAUSE",
  "memPresencePerDay": "PRÉSENCE DU MEMBRE PAR JOUR",
  "memName": "Nom du membre",
  "totDays": "TOTAL DE JOURS",
  "totWorkDays": "JOURS DE TRAVAIL TOTAL",
  "dayPre": "JOURS PRÉSENTS",
  "dayAbs": "JOURS ABSENTS",
  "attendance": "Pourcentage de présence",
  "attAdv": "Rapport d'assiduité avancé",
  "attAdvDesc": "En plus d'une vue d'ensemble de la présence de l'équipe, vous pouvez également accéder à la présence quotidienne de chaque membre.",
  "jobsiteRep": "Rapports de chantier",
  "jobsiteRepDesc": "Consultez les détails complets de chaque membre, y compris le chantier, le nom du projet, le créateur de la tâche et l'heure d'entrée et de sortie.",
  "jobsiteName": "Nom du chantier",
  "jobsite": "CHANTIER",
  "creator": "CRÉATEUR",
  "enter": "ENTRÉE",
  "exit": "SORTIE",
  "timeofft": "Transaction de congé",
  "tieofftDesc": "Consultez les détails des transactions de congé des membres de l'équipe, tels que le nom de la politique.",
  "sendReportText": "Vous pouvez envoyer le rapport à des adresses e-mail individuelles. Vous pouvez définir le sujet et le corps du message électronique.",
  "emailLabel": "Entrez l'e-mail*",
  "fileType": "Type de fichier*",
  "schedueText": "Vous pouvez planifier l'envoi du rapport à des adresses e-mail individuelles à des intervalles de temps spécifiques. Vous pouvez définir le sujet et le corps du message électronique.",
  "enterName": "Entrez le nom*",
  "frequency": "Fréquence*",
  "col": "Colonnes",
  "add": "AJOUTER",
  "changesMade": "Modifications apportées sur",
  "bookedOn": "Réservé le",
  "noResult": "Aucun résultat trouvé",
  "downloadCSV": "Télécharger au format CSV",
  "scheduledReports": "Rapports planifiés",
  "clickToRemove": "Cliquez ici pour supprimer ce rapport de la barre latérale",
  "clickToAdd": "Cliquez ici pour ajouter ce rapport à la barre latérale",
  "timeActivityTxt": "Temps et activité",
  "weeklyTxt": "Hebdomadaire",
  "apps&Urls": "Applications et URLs",
  "manualEditTxt": "Édition manuelle du temps",
  "projodoTxt": "Projet/Tâche",
  "attendanceBasicTxt": "Présence de base",
  "attendanceAdvanceTxt": "Présence avancée",
  "timeoffTransanctionTxt": "Transaction d'absence",
  "normalBreak": "Pause normale",
  "exceedBreak": "Pause dépassée",
  "TotalHours": "Total des heures",
  "Average": "Moyenne",
  "Productive": "Productif",
  "Unproductive": "Improductif",
  "TimeChange": "Changement de temps",
  "SelectActionType": "Sélectionnez le type d'action",
  "edit": "Éditer",
  "addText": "Ajouter",
  "delete": "Supprimer",
  "Date": "Date",
  "Member": "Membre",
  "Open": "Ouvert",
  "Approved": "Approuvé",
  "Denied": "Refusé",
  "TimesheetType": "Type de feuille de temps",
  "TimeDuration": "Durée",
  "ApprovedDenied": "Approuvé / Refusé par",
  "TimeSpent": "Temps passé",
  "AttendancePer": "Présence (%)",
  "AvgWorkingHours": "Heures de travail moyennes",
  "AvgBreakHours": "Heures de pause moyennes",
  "JobsiteName": "Nom du site de travail",
  "Creator": "Créateur",
  "SelectJosite":"Sélectionnez le site de travail"
  },
  "generalSettingMember": {
    "topPara": "Cessez de vous débattre pour vous souvenir des noms et mettez en place une identification unique des membres/employés avec un identifiant utilisateur.",
    "default": "DÉFAUT",
    "auto": "Automatiquement",
    "manual": "Manuellement",
    "preScen":  "Scénario de préfixe",
    "withPre":  "Avec préfixe",
    "withoutPre": "Sans préfixe",
    "setPreId":  "Définir le préfixe de l'ID",
    "memId":  "Définir l'ID du membre",
    "setPreIdErr":  "Veuillez saisir 3 caractères alphabétiques majuscules",
    "memIdErr":  "Veuillez saisir la valeur entre 0001 et 9999",
    "save": "Sauvegarder",
    "setupMemId" :"Configurer l'ID de membre"
  },
  "memberProfile": {
    "saveChanges": "Enregistrer les modifications",
    "editChanges": "Modifier les détails",
    "cancelChanges": "Annuler les modifications",
    "About": "À propos",
    "job": "Ce que j'aime dans mon travail",
    "interest": "Mes intérêts et loisirs",
    "PI": "Informations personnelles",
    "email": "E-mail secondaire",
    "countryCode": "Code postal",
    "AlternatePhNo": "Autre numéro de téléphone",
    "BG": "Groupe sanguin",
    "maritalStatus": "État civil",
    "marriageDate": "Date de mariage",
    "permAddress": "adresse permanente",
    "JobDetails": "Détails du poste",
    "empId": "ID d'employé",
    "workLocation": "Lieu de travail",
    "Department": "Département",
    "Designation": "Désignation",
    "joiningDate": "Date d'adhésion",
    "empType": "Type d'emploi",
    "identityInfo": "Informations d'identité",
    "missingInfo": "Veuillez saisir les détails manquants",
    "photoId": "photo d'identité",
    "idName": "NOM D'IDENTIFICATION",
    "idNumber": "Numéro d'identification",
    "filelimitWarning": "La taille du fichier dépasse la limite de 1 Mo.",
    "Add New": "Ajouter un nouveau",
    "addressProof": "PREUVE D'ADRESSE",
    "otherproof": "AUTRE IDENTIFICATION/PRUVE",
    "primaryInfo": "Informations primaires",
    "Gender": "Genre",
    "phNo": "Numéro de téléphone",
    "currentAddress": "Adresse actuelle",
    "emergencyDetail": "Détails des contacts d'urgence",
    "ContactName": "Nom du contact",
    "Relation": "Relation",
    "ContactEmail": "Email du contact",
    "contactNo": "numéro de téléphone de contact",
    "contactAddress": "Adresse de contact",
    "bankingInfo": "Information bancaire",
    "bankNo": "Numéro de compte bancaire",
    "FSCode": "Code IFS",
    "BankName": "Nom de banque",
    "BranchName": "Nom de la filiale",
    "BankAddress": "Adresse de la banque",
    "DangerZone": "Zone dangereuse",
    "deleteAccount": "Supprimer votre compte",
    "DeactivateAccount": "Désactiver votre compte",
    "ChangeEmail": "Changer l'e-mail",
    "ChangePassword": "Changer l'e-mail",
    "checkInbox": "Veuillez vérifier votre boîte de réception",
    "mailText": "Nous vous avons envoyé un e-mail pour confirmer votre nouvelle adresse e-mail.",
    "deactivateText1": "Cette action désactivera le compte. Aucune nouvelle donnée ou action ne sera enregistrée dans l'application.",
    "deactivateText2": "Cela n’annulera pas votre abonnement existant. Vous pouvez vous connecter à tout moment pour revoir vos anciennes données ou réactiver votre compte.",
    "continue": "Voulez-vous continuer?",
    "maxFileLimit": "2 fichiers maximum sont autorisés",
    "Credentials": "Identifiants",
    "chooseFile": "Choisir un fichier",
    "CommonHeader": {
      "working": "fonctionnement",
      "reportingMamager": "Responsable des rapports",
      "profilePercentage": "Profil complété :",
      "Profile": "Profil",
      "App Setting": "Paramètres de l'application"
    },
    "appSetting": {
      "generalSetting": "réglages généraux",
      "TimeZone": "Fuseau horaire",
      "timeZone": "Fuseau horaire des membres",
      "changedTimeZone": "Changer de fuseau horaire",
      "billingHours": "Heures de travail et facturation",
      "dailyLimit": "Limite journalière",
      "hrs/wk": "heures/semaine",
      "weeklyLimit": "Limite hebdomadaire",
      "payRate": "Taux de rémunération horaire",
      "weeklyOff": "Congé hebdomadaire",
      "TimerApp": "Application de minuterie",
      "screenshotTimer": "Minuterie de capture d'écran",
      "breakTime": "Pause",
      "breakLimit": "Limite de temps de pause",
      "idleNote": "Remarque",
      "trackingMode": "Mode de suivi",
      "daysCount": "Nombre de jours",
      "trackingTime": "Temps de suivi",
      "featureSetting": "Paramètres des fonctionnalités",
      "Timesheet": "Emploi du temps",
      "editTimesheet": "Modifier la feuille de temps",
      "timesheetApproval": "Feuille de temps d'approbation",
      "reasonReqire": "Exiger une raison",
      "limitTimesheet": "Feuille de temps après la limite",
      "Activity": "Activité",
      "App&URLsTracking": "Suivi des applications et des URL",
      "activityMenu": "Menu d'activité",
      "ssFequency": "Fréquence de capture d'écran",
      "ssAlert": "Alerte de capture d'écran",
      "SsBlur": "Flou de capture d'écran",
      "deleteSs": "Supprimer la capture d'écran",
      "Jobsite": "Chantier",
      "restrictTimer": "Restreindre la minuterie aux chantiers",
      "trackJobsiteTime": "Suivre le temps sur les chantiers",
      "defineBreak": "Définir la pause",
      "Visible": "Visible",
      "Stealth": "Furtivité",
      "trackingModeTxt": "Contrôlez le mode de suivi pour votre organisation ou pour tout utilisateur individuel",
      "restrictTimerTab": "Limiter le minuteur aux chantiers",
      "enterExitNotification": "Notifications d'entrée/sortie",
      "Enabled": "Activé",
      "Disabled": "Désactivé",
      "integrationTxt": "Vous n'avez effectué aucune intégration pour votre organisation",
      "Enabled but Not Mandatory": "Activé mais pas obligatoire",
      "Enabled & Mandatory": "Activé et obligatoire",
      "Individual": "Individuelle",
      "All": "Toute"
    },
    "deleteAccountConfirmation": "Êtes-vous sûr de vouloir supprimer votre compte."
  },
  "memberId": {
    "text1": "Arrêtez de lutter pour mémoriser les noms et configurez un identifiant unique de membre/employé pour l'identification de l'utilisateur.",
    "default": "DÉFAUT:",
    "Automatically": "Automatiquement",
    "Manually": "Manuellement",
    "prefix": "Scénario de préfixe",
    "withpre": "Avec préfixe",
    "withoutPre": "Sans préfixe",
    "preID": "Définir le préfixe de l'ID",
    "membId": "Définir l'ID du membre",
    "text2": "Veuillez saisir 3 caractères alphabétiques majuscules",
    "text3": "Veuillez saisir la valeur entre 0001 et 1000",
    "text4": "Ce paramètre ne peut jamais être modifié en mode manuel. Êtes-vous sûr de vouloir mettre à jour ce paramètre ?",
    "Prefix": "préfixe",
    "ID": "ID",
    "uniqueID":"Définissez un identifiant unique pour les membres de votre organisation.",
    "setPrefixID": "Définissez le préfixe pour l'identifiant du membre. Il doit contenir uniquement 3 caractères alphabétiques.",
    "setMemberId": "Définissez l'identifiant qui sera affiché devant le préfixe, et la plage doit être de 0001 à 10000000.",
    "setMemberIdWithout":"Définissez l'identifiant du membre sans préfixe, et la plage doit être de 0001 à 10000000.",
    "memberIdErrTxt": "Veuillez saisir une valeur entre 0001 et 99999999"
  },
  "Designation": {
    "text1": "Définir un département pour tous les membres de l'organisation",
    "addDesignation": "Ajouter une désignation",
    "Designation": "Désignation",
    "delDesignation" :"Supprimer la désignation",
    "editDesignation": "Modifier la désignation",
    "assignDelete": "Supprimer et attribuer",
    "removeDesignation": "Supprimer cette désignation ?",
    "delDesignationText1": "en permanence. Souhaitez-vous toujours continuer ?",
    "delDesignationText2": "sera supprimé",
    "updateDesig": "Mettre à jour la désignation",
    "desigName": "Nom de la désignation",
    "Update": "Mise à jour",
    "define": "Définir le niveau ou la position que les employés occupent au sein de l'organisation.",
    "seeting": "Paramètres de désignation"
  },
  "memberSetting": {
    "Membsettings":"Paramètres des membres",
    "open": "Ouvrir",
    "Restricted": "Limité",
    "profileSetting": "Paramètre de profil",
    "MembSetting": "Paramètres des membres",
    "text1": "Aucun widget n'est présent pour le moment !",
    "text2": "Êtes-vous sûr de vouloir mettre à jour ce paramètre ?",
    "text3": "Ici, vous pouvez personnaliser les paramètres de chaque membre."
  },
  "time-activity-report":{
    "activity_time": "Temps d'activité %",
    "idle_time": "Temps d'inactivité 5",
    "effective_time": "Temps efficace",
    "manual_time": "Temps manuel %",
    "break_time": "Temps de pause",
    "total_hrs": "Total heures"
  },
  "youTubeVideo": {
    "welcome": "Bienvenue sur Workstatus",
    "setingUp": "Besoin d'aide pour la configuration?",
    "scheduleCallwithTeam": "Planifiez un appel avec notre équipe de réussite client.",
    "scheduleCall": "Planifier un appel",
    "productTour": "Voir la visite guidée du produit",
    "skip": "Passer pour continuer",
    "helpdesk": "Service d'assistance"
  },
  "wsTimePicker": {
    "Hours": "Heures",
    "Minutes": "Minutes"
  },
  "generalsetting": {
    "officeLocation" : "Lieu de travail",
    "text": "Collez une image, faites glisser et déposez des fichiers ou",
    "click": "cliquez ici",
    "upload": "pour télécharger",
    "Save": "Enregistrer",
    "Attach": "Attacher",
    "Cancel": "Annuler",
    "sizelimit": "La taille du fichier doit être inférieure à 50 Ko",
    "defaultRole": "Rôle par défaut des membres",
    "projectID": "Gérer l'identifiant du projet",
    "todoStatus": "Gérer l'état des tâches à faire",
    "ownerToolTip" : "Vous ne pouvez pas modifier les paramètres du compte propriétaire",
    "manageID": "Gérer l'identifiant des tâches à faire",
    "milestone":"Étape du Projet",
    "financeSetting": "Paramètres financiers",
    "projectSetting": "Paramètres du projet",
    "User": "Utilisateur",
    "DeliveryHead": "Chef de livraison",
    "None": "Aucun",
    "orgOwner": "Propriétaire de l'organisation",
    "orgManger": "Gestionnaire de l'organisation",
    "noMembers": "Il n'y a actuellement aucun membre présent à cet instant !",
    "noProjects": "Il n'y a aucun projet actuellement!",
    "sureToUpdate": "Êtes-vous sûr(e) de vouloir mettre à jour ce paramètre ?",
    "breakLimit": {
      "text1": "Définir la limite de temps de pause pour tous les membres ou certains membres sélectionnés",
      "editBreak": "Modifier la pause"
      },
      "manageIDText": "Arrêtez de vous battre pour mémoriser les noms et configurez des identifiants de projet uniques pour l'identification du projet.",
      "manageIdText1": "Une fois enregistré, aucune modification future n'est autorisée pour ce paramètre.",
      "validation": "Veuillez saisir un numéro compris entre 0001 et 99999999",
      "Enable": "Activer",
      "Disable": "Désactiver",
      "DEFAULT": "PAR DÉFAUT :",
      "statusName": "NOM DE L'ÉTAT DES TÂCHES À FAIRE",
      "editSTatus": " Modifier l'état des tâches à faire",
      "statusAlreadyAssigned": "Cet état des tâches à faire est déjà attribué à certaines tâches, veuillez changer l'état des tâches avant de désactiver cet état des tâches.",
      "enableDisable": "Arrêtez de vous battre pour vous souvenir des noms et configurez des identifiants de tâches uniques pour l'identification des tâches.",
      "WeeklyOff": "Jour de congé hebdomadaire",
      "allowMembers": "Autoriser les membres à choisir leurs jours de travail",
      "settings": "paramètres",
      "editText1": "Si vous ne trouvez pas le fuseau horaire exact dans votre système, veuillez sélectionner la ville la plus proche avec le même code GMT.",
      "allowmembers": "Autoriser les membres à choisir leur fuseau horaire",
      "timezonelist": "Les fuseaux horaires répertoriés ci-dessus sont sans heure d'été",
      "fileSize": "La taille du fichier doit être inférieure à 2 Mo",
      "changesAlert": "Vous êtes sur le point de changer le fuseau horaire de votre organisation, veuillez noter les points suivants :",
      "alert1": "Cela changera le fuseau horaire de tous les membres de votre organisation.",
      "alert2": "Sur les applications de suivi (applications de bureau et mobile), le nouveau changement de fuseau horaire ne prendra effet qu'une fois que vous vous déconnecterez et vous reconnecterez ou rechargerez les données de l'organisation.",
      "updateSetting": "Êtes-vous sûr(e) de vouloir continuer - si OUI, appuyez sur OK et ENREGISTREZ le paramètre ?",
      "noMembersPresent": "Il n'y a actuellement aucun membre présent à cet instant !",
      "billRate": "Taux de facturation",
      "changeTimezone": "Permettre aux membres de changer leur fuseau horaire.",
      "selectCity": "Si vous ne trouvez pas le fuseau horaire exact dans votre système, veuillez sélectionner la ville la plus proche avec le même code GMT.",
      "No": "Non",
      "Yes": "Oui",
      "Clear": "Effacer",
      "Apply": "Appliquer",
      "diffTimezone": "Fuseau horaire différent",
      "Filters": "Filtres",
      "allowWeekOff": "Autoriser les membres à changer leur jour de congé.",
      "selectOneDay": "Veuillez sélectionner au moins un jour comme jour de travail",
      "pleaseSelectDay": "Veuillez sélectionner les jours",
      "Upgrade": "Mise à niveau",
      "timerApps": "Contrôler les applications de minuterie que le membre de l'équipe peut utiliser.",
      "OK": "OK",
      "enableMandatory": "Activé et obligatoire",
      "enable": "Activer mais non obligatoire",
      "Disabled": "Désactivé",
      "Unlimited": "Illimité",
      "Fixed": "Fixe",
      "Start": "Début",
      "time": "temps",
      "End": "Fin",
      "MEMBERS": "MEMBRES",
      "trackingMode": "Mode de suivi",
      "NoOfDays": "Nombre de jours",
      "manageTodoStatus": "Créez un statut ToDo personnalisé pour gérer et catégoriser les tâches plus efficacement.",
      "confirmTxt": "Les paramètres que vous essayez de mettre à jour affecteront tous les utilisateurs. Nous devrons donc recharger la page pour la rendre efficace à l'échelle du système. Souhaitez-vous continuer ?",
      "payRate": "Taux de rémunération"
  },
  "editLimit": {
    "designation": "Désignation du membre",
    "hrs/wk": "hrs/semaine",
    "hrs/day": "hrs/jour",
    "limitValidation": "La limite quotidienne ne doit pas dépasser la limite hebdomadaire",
    "₹/hrs": "₹/hrs"
  },
  "commonVar": {
    "OK": "OK",
    "No": "Non",
    "Yes": "Oui",
    "cancel": "Annuler",
    "Save": "Enregistrer",
    "searchmember": "Rechercher des membres",
    "notAutorisedTxt": "Vous n'avez pas accès à cette page.",
    "inConvinienceTxt": "Nous sommes désolés pour la gêne occasionnée.",
    "showMore": "Afficher plus...",
    "showless": "Afficher moins...",
    "trackerLogoutMsg":"Veuillez arrêter votre tracker, puis vous pourrez vous déconnecter.",
    "trackerTimeError":"Erreur de changement de date/heure",
    "trackerTimeErrorDes":"Un changement dans la date/heure de votre système a été détecté. Workstatus Web Tracker sera arrêté à cause de cela. Veuillez corriger la date/heure de votre système afin de continuer le suivi Web du statut de travail."
  },
  "membersTab": {
    "noMembers": "Il n'y a pas de membres dans ce projet !",
    "invite": "Inviter de nouveaux membres",
    "sureToRemove": "Êtes-vous sûr de vouloir supprimer le membre ?",
    "remove": "Une fois supprimé du projet, le membre ne pourra plus accéder aux données de votre organisation (projets, tâches à faire, etc.). Êtes-vous sûr de vouloir supprimer le membre ?",
    "successMsg": "La demande d'invitation du membre a été envoyée avec succès.",
    "tooltipData": "En mode furtif, l'application fonctionnera en arrière-plan et l'utilisateur n'aura pas besoin d'intervenir dans l'application de quelque manière que ce soit. Ils n'auront pas besoin de démarrer/arrêter l'application. Remarque : Disponible uniquement pour Windows."
  },
  "featureLimit": {
    "proceed": "Laisser le minuteur suivre normalement",
    "stopAlert": "Arrêter le minuteur et afficher une alerte",
    "teamsSetting": "Ce paramètre fonctionne en fonction des équipes. Veuillez d'abord ajouter une équipe pour utiliser ce paramètre. Créez une équipe depuis",
    "Team": "Équipe",
    "PRODUCTIVE": "PRODUCTIF",
    "UNPRODUCTIVE": "NON PRODUCTIF",
    "addProductive": "Ajouter Productif",
    "addHere": "Ajouter ici."
    },
    "jobsiteNotification": {
      "Yes": "Oui",
      "No": "Non"
      },
    "choosePlan": {
    "plan": "Choisissez le plan qui convient à votre équipe",
    "planDependency": "En fonction de vos besoins uniques, choisissez le plan qui vous convient. Consultez la liste des fonctionnalités ci-dessous pour vous aider à prendre la bonne décision.",
    "Save": "Enregistrer",
    "Monthly": "Mensuel",
    "Annual": "Annuel",
    "feature": "Fonctionnalités du plan",
    "text1": "Trouvez l'abonnement qui",
    "text2": "a le plus de sens pour vous ou votre équipe.",
    "customPlan": "Besoin d'un plan personnalisé pour votre organisation",
    "customplanText": "Ne vous inquiétez pas ! Notre logiciel est prêt à s'adapter à vos besoins uniques ; contactez-nous pour personnaliser Workstatus pour votre entreprise.",
    "requestCustom": "Demander un devis personnalisé",
    "upgrade": "Mettez à niveau, rétrogradez ou annulez votre plan à tout moment.",
    "stealthMode": "Utilisez le mode furtif pour un suivi ininterrompu.",
    "smartDecision": "Prenez des décisions commerciales intelligentes basées sur les rapports d'IA.",
    "regularization": "Conformité à la loi sur le Règlement général sur la protection des données (RGPD).",
    "increaseProductivity": "Augmentez la productivité, la rentabilité et la transparence sur le lieu de travail."
    },
    "Addpolicy": "Ajouter une politique",
    "efficiencyPro": {
      "resetIcon": "Réinitialiser l'icône",
      "changepicture": "Changer l'image",
      "changeIcon": "Changer l'icône",
      "size": "(Taille maximale de l'icône 50 Ko)",
      "Disable": "Désactiver",
      "Enable": "Activer",
      "text": "Collez une image, faites glisser et déposez des fichiers ou",
      "click": "cliquez ici",
      "upload": "pour télécharger",
      "fileSize": "Taille maximale : 50 ko, Format pris en charge - .PNG",
      "Save": "Enregistrer",
      "Attach": "Attacher",
      "Cancel": "Annuler",
      "sizelimit": "La taille du fichier doit être inférieure à 50 Ko",
      "efficiency": "PRO EFFICACITÉ"
    },
    "searchField": {
      "todo": "Rechercher le statut des tâches à faire",
      "members": "Rechercher des membres",
      "projects": "Rechercher des projets",
      "selectOption": "Sélectionner les options",
      "searchDesignation": "Rechercher la désignation",
    "searchMilestone": "Recherche d'étape"
    },
    "editTodoStatus": {
      "addNewTodoGroup" : "Ajouter un nouveau groupe de tâches",
      "edit": "Modifier l'état de la tâche",
      "text1": "La modification de l'état des tâches à faire devient amusante ; attribuez simplement une couleur à chaque état.",
      "BI": "Informations de base",
      "text2": "La longueur du nom de l'étiquette ne doit pas dépasser 20 caractères.",
      "SelectColor": "Sélectionner la couleur",
      "Status": "État",
      "Save": "Enregistrer",
      "label": "Étiquette",
      "editTodoGroup": "Modifier le Groupe de Tâches",
      "todoGroupErr": "La longueur du nom du groupe de tâches ne doit pas dépasser 100 caractères.",
      "tooltip1": "Ce groupe de tâches sera visible à la fois par les membres du projet et les utilisateurs du client.",
      "tooltip2": "Ce groupe de tâches ne sera visible que par les membres du projet.",
      "flag": "Indicateur*",
      "todoGroupName": "Nom du Groupe de Tâches*",
      "addTodogroup": "Ajouter un Nouveau Groupe de Tâches",
      "nodataTodoSelect": "Sélectionnez d'abord le projet pour charger les membres associés.",
      "noTodo": "Aucune tâche à faire ici - veuillez choisir un autre groupe/projet Todo."
    },
    "footer": {
      "policy": "Politique de confidentialité",
      "service": "Conditions d'utilisation",
      "reportBug": "Signaler un bug",
      "suggestFeature": "Suggérer une fonctionnalité",
      "Copyright": "Droits d'auteur",
      "rightReserved": "Tous droits réservés"
    },
    "allDropdown": {
      "allMembers": "Tous les membres",
      "allJobsites": "Tous les sites de travail",
      "hoursLevel": "Tous les niveaux d'heures",
      "AllTeams": "Toutes les équipes",
      "AllDepartments": "Tous les départements",
      "Date": "Date",
      "Member": "Membre",
      "allproject": "Tous les projets",
      "activePolicy": "Politique active",
      "TimeOffPolicies": "Politiques de congé",
      "Escalation": "Échelonnement",
      "upgrade": "Amélioration",
      "archivePolicy": "Politique d'archivage",
      "memberTimezoneDropdown": "Membre(s) avec un fuseau horaire différent"
    },
    "viewTimeOff": {
      "view": "Voir la demande de congé",
      "viewIndividual": "Voir les détails de la demande de congé individuelle à partir d'ici.",
      "GI": "Informations générales",
      "Back": "Retour",
      "Member": "Membre",
      "policyName": "Nom de la politique",
      "startdate": "Date de début",
      "EndDate": "Date de fin",
      "Hours": "Heures",
      "request": "Demandé le",
      "Status": "Statut",
      "submitted": "soumis",
      "Userreason": "Raison de l'utilisateur",
      "EditRequest": "Modifier la demande"
    },
    "editTimeOff": {
      "edit": "Modifier la demande",
      "text": "Vos employés peuvent demander du temps libre ; vous pouvez voir, accepter ou refuser la demande de congé depuis notre plateforme unifiée.",
      "BI": "Informations de base",
      "currentbalance": "Solde actuel",
      "totalTime": "Temps total demandé",
      "remaining": "Solde restant",
      "negativeBalance": "La politique de temps sélectionnée n'autorise pas de solde négatif",
      "negativeLimit": "La politique sélectionnée n'autorise pas de soldes négatifs inférieurs à 100",
      "lessHours": "Veuillez entrer un nombre d'heures demandées inférieur à",
      "hours": "heures"
    },
    "label": {
      "Member": "Membre",
      "Policy": "Politique*",
      "timespan": "Période de temps*",
      "allday": "Toute la journée",
      "request": "Temps demandé par jour*",
      "reason": "Raison*",
      "time": "Temps*"
    },  
    "pageNotFound": {
      "text": "Nous avons cherché partout pour cette page. Êtes-vous sûr que l'URL du site web est correcte ? Contactez le propriétaire du site, ou retournez à la page d'accueil.",
      "backHome": "Retour à la page d'accueil",
      "pageNotFound": "Oops... page 404",
      "logoutContinue": "Etes-vous sûr de vouloir vous déconnecter ?",
      "logoutSuccess": "Vous êtes déconnecté avec succès."
    },
    "achievement": {
      "activityGoal": "OBJECTIF D'ACTIVITÉ",
      "Done": "Terminé"
    },
    "break": {
      "addBreak": "Ajouter une pause",
      "updateBreak": "Mettre à jour la pause",
      "Update": "Mettre à jour",
      "Save": "Enregistrer",
      "Status": "Statut",
      "name": "Nom de la pause"
    },
    "remainingText": {
      "selectDays": "Veuillez sélectionner des jours"
    },
    "departmentSetting": {
      "textfeild": "Permettez au département d'ajouter une hiérarchie supplémentaire dans votre organisation pour optimiser votre flux de travail.",
      "setting": "Paramètres du Département",
      "Permissions": "Permissions",
      "approve": "Approuver les feuilles de temps :",
      "text": "Le responsable du département serait en mesure d'approuver ou de rejeter la feuille de temps remplie par les membres/équipes du département.",
      "timeoff": "Demandes de congé :",
      "text1": "Le responsable du département serait en mesure de demander des congés pour les membres/équipes de son département. De plus, il serait en mesure d'approuver les demandes de congé soumises par les membres/équipes du département.",
      "createSchedule": "Création de plannings :",
      "text3": "Le responsable du département serait en mesure de créer des plannings pour les membres/équipes du département.",
      "Enabled": "Activé",
      "Disabled": "Désactivé",
      "text4": "D'un simple clic, activez ou désactivez les paramètres du département."
    },
    "profileSetting": {
      "memberId": "Identifiant de membre/employé",
      "Designation": "Désignation du membre",
      "Settings": "Visibilité du profil de membre"
    },                 
    "PaginationKeys":{
      "showing": "Affichage",
      "to": "à",
      "from": "depuis",
      "entries": "entrées",
      "of": "de"
    },
    "placeholder": {
      "searchMilestoneList": "rechercheListe des jalons",
      "searchmember": "Rechercher un membre",
      "search": "Rechercher",
      "searchmembers": "Rechercher des membres",
      "Searchproject": "Rechercher un projet",
      "searchTeam": "Rechercher des équipes",
      "select": "Sélectionner",
      "selectClient": "Sélectionner le client",
      "totalDays": "Nombre total de jours",
      "searchMilestone": "Rechercher un jalon",
      "SelectTimeType": "Sélectionnez le type de temps",
      "SelectBreakType": "Sélectionnez le type de pause"
    },
    "freeforever": {
      "text1": "veuillez vous référer aux détails ci-dessous :",
      "text2": "Nous avons observé que vous utilisez des fonctionnalités supplémentaires par rapport à ce qui est disponible dans notre",
      "Youve": "Vous avez",
      "users, only": "utilisateurs uniquement",
      "allowedIn": "l'utilisateur est autorisé dans",
      "remove": "veuillez supprimer",
      "text3": "membres de votre organisation. Ne vous inquiétez pas - le montant au prorata vous sera remboursé.",
      "Member(s):": "Membre(s) :",
      "Remove": "Supprimer",
      "projects, only": "projets uniquement",
      "projectAllowed": "projets sont autorisés dans",
      "archieve": "veuillez archiver",
      "orgProject": "le projet de votre organisation.",
      "Project(s):": "Projet(s) :",
      "Archive": "Archiver",
      "removedText": "Une fois retiré du projet, le membre ne pourra plus accéder aux données de votre organisation (c'est-à-dire les projets, les tâches, etc.). Êtes-vous sûr de vouloir supprimer le membre ?",
      "addon": "Ajouter des modules complémentaires",
      "user": "utilisateur",
      "reviewDetails": "Vérifiez vos détails",
      "Plan Info:": "Informations sur le plan :",
      "billingPlan": "Plan de facturation",
      "changePlan": "Changer de plan",
      "purchasedate": "Date d'achat",
      "Personal Info:": "Informations personnelles :",
      "Name": "Nom",
      "Phone": "Téléphone",
      "Email": "E-mail",
      "companyName": "Nom de l'entreprise",
      "details": "Détails de paiement",
      "due": "Montant dû",
      "coupon": "(Avez-vous un coupon ?)",
      "User": "Utilisateur",
      "validation": "Minimum 1 et maximum 500 utilisateurs sont autorisés",
      "Remove user": "Supprimer l'utilisateur",
      "Discount": "Remise",
      "PromoCode": "Code promotionnel",
      "Apply": "Appliquer",
      "Subtotal": "Sous-total",
      "totalDue": "Montant total dû",
      "proceed": "Procéder au paiement",
      "text5": "Encore indécis ? Découvrez toutes nos fonctionnalités avec un",
      "text6": "jours D'ESSAI GRATUIT. Commencez votre essai dès maintenant !",
      "start": "Démarrer mon",
      "freeDays": "jours d'essai gratuit",
      "invalidCoupon": "Code de coupon invalide",
      "woohoo!": "Hourra !",
      "applied": "appliqué",
      "You save ₹": "Vous économisez ₹",
      "promocode": "avec ce code promotionnel",
      "LetProceed": "Procédons",
      "removeMem": "Supprimer les membres",
      "text7": "Veuillez supprimer les membres de votre organisation. Ne vous inquiétez pas - le montant au prorata vous sera remboursé.",
      "THANKYOU": "MERCI",
      "Continue": "Continuer",
      "Back": "Retour",
      "error": "Erreur de traitement du paiement",
      "text8": "Nous avons reçu l'erreur suivante lors de la tentative de paiement :",
      "sessionExpired": "Cette session a expiré.",
      "text9": "Veuillez résoudre le problème ci-dessus et réessayer le paiement.",
      "InvoiceErr": "Veuillez cocher la case de la facture pour envoyer un e-mail",
      "downloadErr": "Veuillez cocher la case de la facture à télécharger",
      "yourInvoiceTxt": "Votre facture"
  },
  "VerticalNav":{
    "downloadApps": "Téléchargez nos applications",
    "chooseAppsTxt": "Choisissez l'application qui facilite le suivi du temps. Travaillez où que vous soyez avec les versions de bureau ou mobile.",
    "desktopApps": "Applications de bureau",
    "mobileApps": "Applications mobiles",
    "mainMenu": "Menu principal",
    "gettingStartedTxt": "Démarrez avec Workstatus",
    "skipTxt": "Passer cette étape",
    "addTeamTxt": "Ajouter des membres de l'équipe",
    "inviteText": "Invitez votre équipe pour qu'elle puisse commencer à suivre le temps. Vous pouvez définir son rôle, les affecter à des projets et même définir des limites hebdomadaires, des taux de facturation et des taux de rémunération.",
    "trackTimeTxt": "Suivi et ajout de temps",
    "setup": "CONFIGURATION",
    "application": "Applications",
    "addTimeManually": "Ajouter l'heure manuellement"
  },
  "onboardingStatus": {
    "onboardingstatus": "Statut d'intégration",
    "name": "NOM DU MEMBRE",
    "account": "COMPTE CRÉÉ",
    "download": "TÉLÉCHARGER L'APPLICATION",
    "trackedTime": "TEMPS SUIVI",
    "reminder": "ENVOYER UN RAPPEL",
    "requestedOn": "demandé le",
    "invitedOn": "Invité le",
    "createdOn": "Compte créé le",
    "downloadedOn": "Application téléchargée le",
    "timetracked": "Temps suivi le"
  },
  "milestone":{
    "mileEnableDisable": " Voici où vous pouvez facilement activer/désactiver les jalons de votre projet.",
    "enable": "Activer",
    "disable": "Désactiver",
    "sureToUpdate": "Êtes-vous sûr(e) de vouloir mettre à jour ce paramètre ?",
    "update": "Jalon mis à jour",
    "addMile": "Ajouter une nouvelle étape",
    "mileDescription": "Définissez vos étapes et commencez à les suivre : ajoutez des informations de base, des détails et appuyez sur Enregistrer.",
    "basicInfo": "Informations de base",
    "milestoneName": "Nom de l'étape",
    "letterValidation": "Les trois premières lettres doivent être des lettres de l'alphabet",
    "limitValidation": "Le nom de l'étape ne doit pas dépasser la limite de 50 caractères",
    "projectName": "Nom du projet",
    "startDate": "Date de début",
    "endDate": "Date de fin",
    "dateCheck": "La date de fin doit être postérieure à la date de début.",
    "flag": "Drapeau",
    "internal": "Interne :",
    "internalDesc": "Les étapes ne seront visibles que par les utilisateurs du projet s'ils sont marqués comme internes.",
    "external": "Externe :",
    "externalDesc": "Les étapes seront visibles par les utilisateurs du projet et du client s'ils sont marqués comme externes.",
    "budgetedHours": "Heures budgétisées",
    "projectOwner": "Propriétaire du projet",    
    "milestoneCreated": "Étape créée avec succès",
    
    "milestoneDetails": "Détails des jalons",
    "Milestones": "Étapes",
    "ViewAllMilestones": "Voir tous les jalons",
    "AddMilestone": "Ajouter une étape"


  },
    "welcomeTour":{
    "welcomeText" : "Bienvenue sur Workstatus !",
    "helpText" : "Nous sommes là pour vous aider à configurer votre compte.",
    "letsStart" : "Commençons" 
  },
   "ProductivitySummary": {
    "productivity": "Résumé de productivité",
    "duration": "Durée",
    "effectiveTime": "Temps efficace",
    "productivityTime": "Temps de productivité",
    "idleTime": "Temps d'inactivité",
    "manualTime": "Temps manuel",
    "activityPercentage": "Pourcentage d'activité",
    "idlePercentage": "Pourcentage d'inactivité",
    "breakTime": "Temps de pause"
  },
  "Milestone": {
      "editMilestone": "Modifier la borne",
      "individualMilestone": "Affichez les détails de la borne individuelle ici et modifiez rapidement les détails si nécessaire.",
      "details": "Détails de la borne",
      "backMilestone": "Retour à la borne",
      "Cancel": "Annuler",
      "save": "Enregistrer la borne",
      "name": "Nom de la borne",
      "validation": "Le nom de la borne ne doit pas dépasser la limite de 100 caractères",
      "project": "Nom du projet*",
      "Flag": "Drapeau",
      "visiblity": "Les bornes ne seront visibles que par les utilisateurs du projet s'ils sont marqués comme internes.",
      "Internal": "Interne :",
      "External": "Externe :",
      "externalVisiblity": "Les bornes seront visibles par les utilisateurs du projet et du client s'ils sont marqués comme externes.",
      "startDate": "Date de début",
      "endDate": "Date de fin",
      "dateValidation": "La date de fin doit être supérieure à la date de début.",
      "budget": "Heures budgétisées",
      "budgtvalidation": "Le nombre d'heures budgétisées doit être inférieur à 10",
      "additionalInfo": "Informations supplémentaires",
      "owner": "Propriétaire de l'étape",
      "createdby": "Créé par et heure",
      "lastEdited": "Dernière modification",
      "viewMilestone": "Voir la borne",
      "investedhours": "Heures investies",
      "showOnlyCr": "Afficher uniquement CR",
      "numberLimit": "Seuls les nombres compris entre 0 et 100 sont acceptés."
  },
  "TimeOff": {
    "monthlyPattern": "modèle mensuel",
    "leaveBalance": "laisser le solde",
    "leaveData": "leave data",
    "weeklyPattern": "modèle hebdomadaire",
    "balance": "Équilibre",
    "consumed": "Consommé",
    "leaveHistory": "quitter l'histoire",
    "leaveType": "Type de congé",
    "leaveDuration": "Durée du congé",
    "leaveNote": "Laisser une note",
    "status": "Statut",
    "approverNote": "note de l'approbateur"
   },
   "comments": {
    "comment": "COMMENTAIRES",
    "sendTxt": "Envoyer à tous",
    "editComment": "Modifier le commentaire",
    "deleteComment": "Supprimer le commentaire",
    "quoteComment": "Commentaire de citation",
    "loadMore": "Charger plus de commentaires",
    "confirmComplete": "Êtes-vous sûr de vouloir supprimer ce commentaire ?",
    "bulkDelete":{
      "first":"Êtes-vous sûr de vouloir supprimer",
      "last":"Les commentaires supprimés ne peuvent pas être récupérés"
    },
    "enterTagName": "Entrer un nom d'étiquette.",
    "Internal": "Interne",
    "External": "Externe",
    "alertText": "Quelque chose s'est mal passé !"
  },
  "todoGroup": {
    "todogroup" : "Groupes de tâches à faire",
"Comments" : "Commentaires",
    "todoGroups": "GROUPES DE TÂCHES",
    "progress": "PROGRÈS",
    "delete": "Supprimer",
    "sureDelete": "Êtes-vous sûr(e) de vouloir supprimer ce groupe de tâches ?",
    "sureMarkAsComplete": "Êtes-vous sûr(e) de vouloir marquer ce groupe de tâches comme terminé?",
    "sureMove": "Êtes-vous sûr de vouloir déplacer ce groupe de tâches ?",
    "markAsComplete": "Marquer comme complète",
    "move": "Déplacer",
    "chooseMilestone": "Choisir une étape importante",
    "chooseProject" : "Choisir un projet",
    "Search": "Rechercher",
    "SelectProject": "Sélectionner un projet",
    "SelectMilestone": "Sélectionner une étape importante",
    "Move": "Déplacer",
    "Cancel": "Annuler",
    "Open": "Ouvrir",
    "TodoGroup": "Groupe de tâches",
    "Todos": "Tâches",
    "TodoCompleted": "Tâches terminées",
    "NoDataFound": "Aucune donnée trouvée",
    "Completed": "Terminé",
    "newTodoGroup": "Nouveau groupe de tâches"
  },
  "SearchBarText": {
    "screenWiseHelp": "Aide spécifique à l'écran",
    "downloadApps": "Télécharger des applications",
    "welcomeToWorkstatus": "Bienvenue chez Workstatus",
    "selectThemesMode": "Sélectionnez le mode Thèmes",
    "changeLanguage": "Changer la langue",
    "quickLinks": "Liens rapides"
  },
    "drawersText": {
      "noNotes": "Aucune note disponible",
      "manualNotes": "Note(s) manuelle(s)",
      "trackerNotes": "Note(s) de suivi",
      "idleNotes": "Note(s) d'inactivité",
      "CategoryHeading":"Catégories de forums de discussion",
      "CategoryText" : "Configurez des catégories pour ce babillard électronique ci-dessous.",
      "addmore": "Ajouter plus de détails",
      "searchparenttodo": "Rechercher la tâche parente",
    "searchtodogroup": "Rechercher dans le groupe de tâches",
    "enterEmailId": "Entrer l'identifiant e-mail",
    "enterMemberId": "Entrer l'identifiant du membre",
    "selectRole": "Sélectionner un rôle",
    "memberId": "ID du membre",
    "policy": "Politique*",
    "ViewTimeOff": "Voir les congés",
    "requestedOn": "Demandé le",
    "EditTimeoff": "Modifier le congé",
    "EditTimeoffTxt": "Vos employés peuvent demander un congé.",
    "memberName": "Nom du membre",
    "project": "Projet",
    "invite": "Inviter",
    "manager": "Directeur",
    "enterProjectName": "Entrer le nom du projet",
    "client": "Client",
    "selectClient": "Sélectionner un client",
    "description": "Description",
    "typeHere": "Tapez ici",
    "date": "Date",
    "startTime": "Heure de début",
    "endTime": "Heure de fin",
    "note": "Note",
    "addNewMilestone": "Ajouter une nouvelle étape",
    "setYourMilestone": "Définissez vos étapes et commencez à suivre : ajoutez des informations de base, des détails et appuyez sur Enregistrer.",
    "milestoneStoneName": "Nom de l'étape",
    "startDate": "Date de début",
    "endDate": "Date de fin",
    "flag": "Drapeau",
    "milestoneOwner": "Propriétaire de l'étape",
    "milestoneChangeRequest": "Cochez si vous souhaitez faire de cette étape une demande de changement",
    "save": "Enregistrer",
    "newTodo": "Ajouter un nouveau groupe de tâches",
    "markingTodo": "Marquer l'état des tâches devient amusant ; attribuez simplement une couleur à chaque état.",
    "projectName": "Nom du projet",
    "todoGroupname": "Nom du groupe de tâches",
    "relatedMilestone": "Étape liée",
    "none": "Aucun",
    "internal": "Interne",
    "external": "Externe",
    "budgetedHours":"Heures budgétisées",
    "AddTime": "Ajouter Temps Manuel",
    "AddTodo": "Ajouter Tâche",
    "AddTodoGroup": "Ajouter Groupe de Tâches",
    "AddMilestone": "Ajouter Jalon",
    "AddProject": "Ajouter Projet",
    "AddMember": "Ajouter Nouveau Membre",
    "AddTeam": "Ajouter Équipe",
    "AddDepartment": "Ajouter Département",
    "AddClient": "Ajouter Client",
    "AddJobsite": "Ajouter Site de Travail",
    "AddSchedule": "Ajouter Programme",
    "RequestTimeoff": "Demander Congé",
    "projectCurr" : "Devise du projet"
  },
  "drawersFieldPlaceholders": {
    "Manager": "Sélectionner un responsable",
    "Users": "Sélectionner un utilisateur",
    "Member": "Sélectionner un membre",
    "projName": "Sélectionner un projet",
    "SelectTodoName": "Sélectionner une tâche",
    "todoGroupName": "Sélectionner un groupe de tâches",
    "parentTodoName": "Sélectionner la tâche parente",
    "todoName": "Entrer le nom de la tâche",
    "status": "Sélectionner un statut",
    "Description": "Saisir ici",
    "Tags": "Sélectionner ou saisir le nom de l'étiquette",
    "TeamName": "Entrer le nom de l'équipe",
    "TeamMates": "Sélectionner les coéquipiers",
    "TeamLead": "Sélectionner le chef d'équipe",
    "clientEmail": "Entrer l'adresse e-mail du client",
    "ClientProject": "Sélectionner le projet pour ce client",
    "Policy": "Sélectionner une politique",
    "Hours": "Entrer les heures",
    "JobsiteName": "Entrer le nom du chantier",
    "JobsiteAddress": "Entrer l'adresse",
    "Title": "Entrer le titre",
    "DeptName": "Entrer le nom du département",
    "DeptHead": "Sélectionner le chef de département",
    "SelectTeam": "Sélectionner une équipe",
    "milestoneName": "Entrer le nom de l'étape importante",
    "SelectDate": "Sélectionner une date",
    "todoGrp": "Entrer le nom du groupe de tâches",
    "selectFlag": "Sélectionner le drapeau",
    "selectbudget": "Sélectionner le budget",
    "enterbudget": "Entrer le budget",
    "selectMilestoneOwner": "Sélectionner le propriétaire de l'étape"
  },
  "add":{
    "AddTo-do" : "Ajouter une tâche",
    "AddTo-doGroup" : "Ajouter un groupe de tâches",
    "AddMilestone" : "Ajouter une étape importante",
    "reqMilestoneName" : "Nom de l'étape importante*",
    "specialCharVal" : "Les caractères spéciaux ne sont pas acceptés",
    "Internal": "Interne",
    "InternalText": "Les jalons ne seront visibles que pour les utilisateurs du projet s'ils sont marqués comme internes.",
    "External": "Externe",
    "ExternalText": "Les jalons seront visibles à la fois pour les utilisateurs du projet et les clients s'ils sont marqués comme externes.",
    "budgetVal" : "Le nombre d'heures budgétées doit être inférieur à 10 chiffres",
    "reqMilestoneOwner" : "Propriétaire de l'étape importante",
    "SearchProjectMember" : "Rechercher un membre du projet",
    "milestoneChangeRequest" : "Cochez si vous souhaitez marquer cette étape comme une demande de changement",
"milestoneChangeRequestTooltip" : "Vous pouvez créer des étapes distinctes pour les demandes de changement au sein du projet afin de suivre les progrès ou les détails spécifiques liés à la demande de changement.",
"ProjectUpdatedSuccessfully" : "Projet mis à jour avec succès.",
"updateStatus" : "Êtes-vous sûr de vouloir mettre à jour le statut ?",
"StatisticsText" : "Statistiques de toutes les APPLICATIONS UTILISÉES",
"selectNone" : "Sélectionner aucun",
"selectAll" : "Tout sélectionner",
"DataNotAvailable" : "Données non disponibles !",
"GridView" : "Vue en grille",
"TableView" : "Vue en tableau",
"Reject" : "Rejeter",
"searchTeammates" : "Rechercher des coéquipiers",
"SearchTeamleads" : "Rechercher les chefs d'équipe",
"SearchDepartmentHead" : "Rechercher le responsable du département",
"SelectProjectFirst" : "Sélectionnez d'abord un projet",
"AttendanceReports" : "Rapports de présence"
  },
  "filterText": {
    "leaves" : "Congés",
    "absent" : "Absent",
    "allLeaves": "Tous les congés",
    "leaveType" : "Sélectionnez le type de congé",
    "shiftEntry" : "Sélectionnez l'entrée de quart",
    "allShifts" : "Tous les quarts",
    "attendanceEntry": "Sélectionnez l'entrée de présence",
    "allEntry": "Toutes les entrées"
    },
    "descLabel":{
      "projectDesc": "Description du projet",
      "todoDesc": "Description de la tâche"
     },
     "filter":{
      "Deleted": "Supprimé",
      "Deactivated": "Désactivé",
      "deviceType": "Type de périphérique",
      "Mac": "Mac",
      "AllUsers": "Tous les utilisateurs",
      "AllDevice": "Tous les périphériques",
      "RoleType": "Type de rôle",
      "SelectRoleType": "Sélectionner le type de rôle",
      "All": "Tous",
      "TrackingCategory": "Catégorie de suivi",
      "TrackingMode": "Mode de suivi",
      "SelectCategory" : "Sélectionner une catégorie",
      "SelectMode" : "Sélectionner un mode",
      "AllTodo" : "Tout à faire",
      "Alphabetically": "Par ordre alphabétique",
      "LastVisited" : "Dernière visite",
      "CurrentWeek" : "Semaine en cours",
      "NextWeek" : "Semaine prochaine",
      "Rejected" : "Rejeté",
      "Pending": "En attente",
      "Leavepolicy" : "Politique de congé",
      "Days": "Jours",
      "Yettostart" : "Pas encore commencé",
      "InProgress" : "En cours",
      "OnHold" : "En attente",
      "Completed": "Terminé",
      "Cancelled" : "Annulé",
      "Archived": "Archivé",
      "Selecttype": "Sélectionner le type",
      "AllDuration" : "Toute la durée",
      "Select": "Sélectionner",
      "between": "entre",
      "SecondGreaterThanFirstValue" : "La deuxième valeur doit être supérieure à la première valeur",
      "Alltype" : "Tous les types"
     },
     "selectField":{
      "SameAsPayRate" :"Identique au taux de rémunération",
      "SameAsPayRateDesc" :"Définissez le même taux horaire pour ce projet",
      "ProjectMember": "Membre du projet",
      "projNameDesc": "Entrez le nom du projet que vous souhaitez conserver. Le nom du projet ne doit contenir aucun caractère spécial et doit comporter au minimum 3 caractères.",
      "managerdesc": "Ajoutez le membre qui sera le manager de ce projet ou à qui vous souhaitez assigner le rôle de point de contact pour ce projet.",
      "projMemberDesc": "Entrez les membres qui vont travailler sur ce projet ou cette tâche. Ces membres du projet seront ajoutés dans les tâches que vous créerez dans ce projet.",
      "projStatusDesc": "Sélectionnez le statut du projet.",
      "startDateDesc": "Indiquez la date à partir de laquelle vous souhaitez commencer le projet.",
      "endDateDesc": "Indiquez la date à laquelle vous souhaitez terminer ce projet.",
      "projDesc": "Saisissez les détails de ce projet que vous souhaitez montrer aux managers et aux utilisateurs du projet. Cela peut contenir l'objectif du projet, le processus, les réalisations ou toute autre information précieuse/requise liée au projet.",
      "clientDesc": "Veuillez ajouter le client pour lequel vous réalisez ce projet. Pour sélectionner un client à partir d'ici, vous devez d'abord ajouter le client.",
      "billableDesc": "Activez ce bascule si vous souhaitez rendre ce projet facturable. Cela vous aidera à facturer les clients pour les heures réellement facturables.",
      "typeDesc": "Sélectionnez le type de budget que vous souhaitez définir pour ce projet.",
      "hoursDesc": "Définissez le nombre d'heures que vous souhaitez définir comme budget pour ce projet.",
      "costDesc": "Définissez le montant du coût que vous souhaitez définir comme budget pour ce projet.",
      "projPerHrRate":"Définissez le taux horaire de ce projet",
      "BudgetMode" : "Sélectionnez le mode de budget que vous souhaitez définir pour ce projet.",
      "CurrencyType" : "sélectionner le type de devise pour le budget",
      "notifyAtDesc": "Définissez le pourcentage de budget restant lorsque vous souhaitez recevoir une notification.",
      "todoProjName": "Entrez le nom du projet dans lequel vous souhaitez créer les tâches à faire (ToDos).",
      "todogroupDesc": "Sélectionnez le groupe de tâches à faire (ToDo) auquel vous souhaitez associer cette tâche à faire (ToDo).",
      "parentTodoDesc": "Sélectionnez la tâche à faire (ToDo) sous laquelle vous souhaitez créer toute tâche à faire (ToDo) enfant.",
      "todoNameDesc": "Définissez le nom unique de la tâche à faire (ToDo) que vous êtes en train de créer.",
      "todoDesc": "Entrez les détails de cette tâche à faire (ToDo). que vous souhaitez montrer aux membres. Cela peut contenir l'objectif de la tâche à faire (ToDo)., le processus, les réalisations ou toute autre information précieuse / requise liée à la tâche à faire (ToDo).",
      "selectMemDesc": "Entrez les membres qui vont travailler sur cette tâche à faire (ToDo). Seuls les membres ajoutés dans le projet défini ci-dessus peuvent être ajoutés à cette tâche à faire (ToDo).",
      "todoRecurrence": "Cela vous aidera à définir la tâche à faire (ToDo) comme une tâche récurrente.",
      "statusDesc": "Définissez l'état de la tâche à faire (ToDo) en fonction de l'avancement de la tâche à faire. Il peut être modifié à tout moment en modifiant une tâche à faire (ToDo).",
      "priorityDesc": "Définissez la priorité de la tâche à faire (ToDo).",
      "todoStartDate": "Indiquez la date à partir de laquelle vous souhaitez commencer la tâche à faire (ToDo).",
      "todoEndDate": "Indiquez la date à laquelle vous souhaitez terminer cette tâche à faire (ToDo).",
      "todoTypeDesc": "Sélectionnez le type de tâche à faire (ToDo) que vous souhaitez créer. Les améliorations sont pour les nouvelles tâches à faire (ToDos) ou toute tâche que vous définissez à tout membre et les bogues sont pour les problèmes survenus pendant les tâches effectuées par les membres.",
      "todoBillable": "Veuillez activer ce bascule si vous souhaitez rendre cette tâche à faire (ToDo) facturable. Cela vous aidera à émettre une facture aux clients pour les heures réellement facturables.",
      "severityDesc": "Définissez la gravité des bugs en fonction de leur criticité. Le plus critique sera défini comme 'Montre Topper' et le moins critique sera défini comme 'Mineur.'",
      "tagsDesc": "Vous pouvez créer différents tags pour la tâche à faire (ToDo) qui vous aideront à les différencier. Ils vous aideront également à les rechercher et pourront également être utilisés lors de la commentation sur les projets, les jalons et les tâches à faire (ToDos).",
      "estHrDesc": "Définissez le nombre d'heures que vous souhaitez attribuer à cette tâche à faire (ToDo) aux membres.",
      "investedHrsDesc": "Il s'agit des heures qui montrent combien de temps le membre a travaillé sur ce todo. La source du todo investi sera l'application de bureau, l'application mobile ou la saisie manuelle des feuilles de temps.",
      "progressPerc": "Définissez le pourcentage de progrès de la tâche à effectuer."
     },
     "invite": {
      "InviteMembers" : "Inviter",
      "investedHrsDesc": "Il s'agit des heures qui montrent combien de temps le membre a travaillé sur ce todo. La source du todo investi sera l'application de bureau, l'application mobile ou la saisie manuelle des feuilles de temps.",
      "TrackingMode": "Mode de suivi",
      "SelectCategory" : "Sélectionner une catégorie",
      "SelectMode" : "Sélectionner un mode",
      "AllTodo" : "Tout à faire",
      "Alphabetically": "Par ordre alphabétique",
      "LastVisited" : "Dernière visite",
      "CurrentWeek" : "Semaine en cours",
      "NextWeek" : "Semaine prochaine",
      "Rejected" : "Rejeté",
      "Pending": "En attente",
      "Leavepolicy" : "Politique de congé",
      "Days": "Jours",
      "Yettostart" : "Pas encore commencé",
      "InProgress" : "En cours",
      "OnHold" : "En attente",
      "Completed": "Terminé",
      "Cancelled" : "Annulé",
      "Archived": "Archivé",
      "Selecttype": "Sélectionner le type",
      "AllDuration" : "Toute la durée",
      "Select": "Sélectionner",
      "between": "entre",
      "SecondGreaterThanFirstValue" : "La deuxième valeur doit être supérieure à la première valeur",
      "Alltype" : "Tous les types"
     },
     "employeeDirectory":{
      "emplyoee" : "Employé",
      "selectedLocation" : "Sélectionner un lieu",
      "searchLocation" : "Rechercher un lieu",
      "allLocation" : "Tous les lieux",
      "selectDepartment" : "Sélectionner un département",
      "allDepartment" : "Tous les départements",
      "employeeDirectory": "Annuaire des employés",
      "searchEmployee" : "Rechercher un employé",
      "punchedInAt: ":"Pointé à: ",
      "notLoggedInYet": "Pas encore connecté",
      "jobTitle:" : "Titre du poste:",
      "department:" : "Département:",
      "phoneNo:": "Numéro de téléphone:",
      "location:" : "Lieu:"
  },
  "budget":{
    "TotalCost":"Coût total",
    "TotalHours":"Total des heures",
    "BudgetedCost" : "Coût budgété",
    "InvestedCost" : "Coût investi",
    "BudgetedHours" : "Heures budgétées",
    "InvestedHours" : "Heures investies",
    "Hours" : "Heures",
    "Cost" : "Coût",
    "Selectbudget": "Sélectionner un budget",
    "Enterbudget" : "Entrer un budget",
    "startDateSmaller" :"La date de début est antérieure à la date de début du projet",
    "startDateGreater" :"La date de début est postérieure à la date de fin",
    "endDateGreater" : "La date de fin est postérieure à la date de fin du projet",
    "endDateSmaller" : "La date de fin est antérieure à la date de début",
    "projEndDateValid": "La date de fin est antérieure à la date de début du projet", 
    "EstimatedCost" : "Coût estimé",
    "overinvested" : "Surinvesti"
  },
  "memberGraph":{
    "TableView" : "Vue de tableau",
    "GridView" : "Vue de grille",
    "GraphView" : "Vue de graphique",
    "EmployeeDetails" : "Détails de employé",
    "loadMore": "Charger plus"
   },
  "projectTimesheet": {
    "groupBy": "Regrouper par :",
    "date": "Date",
    "member": "Membre",
    "Todo": "Tâches à faire",
    "TaskDetail": "DÉTAIL DE LA TÂCHE",
    "HrsLog": "JOURNAL DES HEURES",
    "TimePeriod": "PÉRIODE DE TEMPS",
    "Status": "STATUT",
    "Date": "DATE",
    "addManualTime": "Ajouter du temps manuellement"
  },
  "officeLocation": {
    "manageLocation": "GÉRER LES LIEUX DE TRAVAIL",
    "locationName": "NOM DE L'EMPLACEMENT",
    "addNewLocation": "Ajouter un nouvel emplacement",
    "enterLocation": "Entrez le nom de l'emplacement",
    "saveLocation": "Enregistrer l'emplacement",
    "editLocation": "Modifier l'emplacement",
    "deleteLocation": "Supprimer l'emplacement",
    "noLocation": "Il n'y a aucun emplacement présent pour le moment !",
    "memberLocation": "Emplacement du membre",
  "reminderTooltip" : "Rappeler de démarrer le minuteur",
  "knowMore" : "Vous pouvez définir des adresses IP spécifiques comme lieux de travail. Lorsque les employés se connectent à partir de ces adresses IP définies, le système détectera automatiquement et affichera les lieux de travail des employés."

  },
  "messageBoardDisscussion": {
    "noMsg" : "Pas encore de messages",
    "noMsgText" : "Publiez des annonces, proposez des idées et recueillez des commentaires tout en gardant les discussions organisées et sur le sujet.",
    "newMsg" : "Nouveau Message",
    "pickCategory" : "Choisissez une catégorie (Optionnel)",
    "typeTitle" : "Tapez un titre...",
    "postMsg" : "Publier le Message",
    "saveDraft" : "Enregistrer comme brouillon",
    "postThis" : "Quand voulez-vous publier ceci ?",
    "searchMsg" : "Rechercher un Message",
    "selectTime" : "Sélectionner l'heure",
    "scheduleSave" : "Programmer & Enregistrer",
    "editMessage" : "Modifier le Message",
    "deleteMessage" : "Supprimer le Message",
    "draft": "Brouillon",
    "privacy" : "Confidentialité",
    "allProjectMember" : "Tous les membres du projet",
    "SearchProjectMember" : "Rechercher un membre du projet",
    "allClients" : "Tous les clients",
    "SearchProjectClient" : "Rechercher un client du projet",
    "editAddCategory" : "Modifier/Ajouter des catégories",
    "goBack": "Retour",
    "LastUpdated" : "Dernière mise à jour"
  },
  "notificationSettings": {
    "ReceiveAllNotifications": "Recevoir toutes les notifications",
    "ShowNotifications" : "Afficher les notifications",
    "allNotification" : "Toutes les notifications",
    "Notifications": "NOTIFICATIONS",
    "email": "EMAIL"
  },
  "graph": {
    "TotalTasks": "Tâches Totales",
    "RecentFirst": "Le plus récent d'abord",
  "RecentLast": "Le plus ancien d'abord",
  "ShareYourCommentHere": "Partagez votre commentaire ici",
  "SearchComments": "Rechercher des commentaires",
  "CopyMilestoneLink": "Copier le lien du jalon",
  "FullScreenview": "Vue en plein écran",
  "DUEDATE": "DATE D'ÉCHÉANCE",
  "ASSIGNEE(S)": "ATTRIBUÉ(E)(S)",
  "CreatedDate": "Date de création",
  "TaskID": "ID de tâche",
  "SelectMilestones": "Sélectionner des jalons",
  "AllMilestones": "Tous les jalons",
  "NoMilestones": "Pas de jalons",
  "ParentTasks": "Tâches parentes",
  "SearchParentTask": "Rechercher une tâche parente",
  "EnterKeyword": "Entrer un mot-clé",
  "Searchtags": "Rechercher des tags",
  "SearchTasksLists": "Rechercher des listes de tâches",
  "Savefilterview": "Enregistrer la vue filtrée",
  "ViewName": "Nom de la vue",
  "Maximumlengthshouldbe35characters": "La longueur maximale doit être de 35 caractères",
  "deletefilter": "Êtes-vous sûr de vouloir supprimer ce filtre?",
  "Updatefilterview": "Mettre à jour la vue filtrée",
  "maxiLimitfilters": "Vous avez atteint la limite maximale d'enregistrement des filtres. Vous pouvez enregistrer au maximum 6 filtres.",
  "deleteTask": "Êtes-vous sûr de vouloir supprimer cette tâche?",
  "CopyTaskLink":"Copier le lien de la tâche",
  "TodoID": "ID à faire",
  "ParentTodoID": "ID à faire parent",
  "NonBillable": "Non facturable",
  "NonRecurring": "Non récurrent",
  "Enhancement": "Amélioration",
  "Bug": "Bug",
  "LowPriority": "Priorité faible",
  "MediumPriority": "Priorité moyenne",
  "HighPriority": "Priorité élevée",
  "AddSubTask": "Ajouter une sous-tâche",
  "MoveTask": "Déplacer la tâche",
  "MoveTo-do": "Déplacer la tâche",
  "Title": "Titre",
  "MessageType": "Type de message",
  "SelectMessageType": "Sélectionner le type de message",
  "CategoryType": "Type de catégorie",
  "SelectCategory": "Sélectionner une catégorie",
  "Comment(s)": "Commentaire(s)",
  "Locations": "Emplacements",
  "OfficeRemote": "Bureau/À distance",
  "origin": "origine",
  "UpdateFilter": "Mettre à jour le filtre",
  "DeleteFilter": "Supprimer le filtre",
  "SaveFilters": "Enregistrer les filtres",
  "ClearFilters": "Effacer les filtres",
  "TaskStatus": "Statut de la tâche",
  "None" : "Aucun",
  "ShowStopper" : "Bloquant",
  "Critical" : "Critique",
  "Major": "Majeur",
  "Minor": "Mineur",
  "Posted": "Publié",
  "Scheduled": "Programmé",
  "Draft": "Brouillon"
  },
  "tracker": {
    "title": "Suivez votre temps",
    "description": "Sélectionnez le projet et la tâche pour commencer à suivre",
    "searchPlaceholder":"recherche...",
    "noData":"Pas de données disponibles",
    "noTaskData":"Aucune tâche assignée",
    "noProjectSelected":"Aucun projet sélectionné",
    "workToday": "Aujourd'hui",
    "projectLabel":"Projet",
    "projectPlaceholder":"Sélectionnez un projet  ",
    "taskLabel":"Tâche",
    "taskPlaceholder":"Sélectionnez une tâche",
    "downloadInfo": {
      "start":"Pour enregistrer des captures d'écran, veuillez ",
      "download":"télécharger ",
      "last":"et utiliser l'application de bureau."
    }
  },
  "gantt": {
    "groupBy": "Grouper par",
    "groupByStatus": "Statut",
    "groupByMember": "Membre",
    "groupByTaskGroup": "Groupe de tâches",
    "sortBy": "Trier par",
    "sortByAlfabetically": "Alphabétiquement",
    "sortByCreatedDate": "Date de création",
    "sortByStartDate": "Date de début",
    "sortByDueDate": "Date d'échéance",
    "sortByTaskId": "ID de la tâche",
    "timelineView": "Vue chronologique",
    "listingView": "Vue en liste",
    "days": "Jours",
    "week": "Semaine",
    "monthly": "Mensuel",
    "quarterly": "Trimestriel"
  },
  "expense": {
    "moduleName":"Dépense",
    "newExpense":"Nouvelle Dépense",
    "listTitle":{
      "SNo":"N°",
      "ExpenseCategory":"Catégorie de Dépense",
      "ProjectName":"Nom du Projet",
      "TaskName":"Nom de la Tâche",
      "TeamMember":"Membre",
      "DateOfExpense":"Date de Dépense",
      "Amount":"Montant",
      "ExpenseDescription":"Description de la Dépense",
      "Attachments":"Pièces Jointes",
      "Status":"Statut",
      "Reason":"Raison"
    },
    "drawer":{
      "title":"Ajouter une Dépense",
      "titleDescription":"Ici, vous pouvez créer une dépense, l'assigner aux bonnes ressources et commencer à la suivre.",
      "categoryLabel":"Catégorie de Dépense",
      "categoryPlaceholder":"Sélectionner la Catégorie de Dépense",
      "projectNameLabel":"Nom du Projet",
      "projectNamePlaceholder":"Sélectionner un Projet",
      "taskLabel":"Nom de la Tâche",
      "taskPlaceholder":"Sélectionner une Tâche",
      "memberLabel":"Nom du Membre",
      "memberPlaceholder":"Sélectionner un Membre",
      "expenseDateLabel":"Date de Dépense",
      "hoursWorkedLabel":"Heures Travaillées",
      "amountLabel":"Montant",
      "descriptionLabel":"Description de la Dépense",
      "descriptionPlaceholder":"Entrer la Description",
      "attachFileText":"Joindre un Fichier",
      "searchPlaceholder":"Recherche..."
    }
  }
  
}